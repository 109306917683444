import { createStore } from 'vuex';

import productsModule from './modules/products/index.js';
import authModule from './modules/auth/index.js';

const store = createStore({
  modules: {
    products: productsModule,
    auth: authModule
  },
  state() {
    return {
      baseDevUrl: process.env.VUE_APP_API_BASE_URL,
      baseProdUrl: ""
    }
  },
  getters: {
    getDevBaseURL: function(state) {
      return state.baseDevUrl;
    },
    getProdBaseURL: function(state) {
      return state.baseProdUrl;
    }
  }
});

export default store;
