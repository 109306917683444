<template>
  <section class="goldies-picks-sec goldies-picks-sec-search-result sec-nav-blur">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="goldies-picks-sec-container goldies-picks-sec-container-empty d-flex flex-wrap w-100">
            <!-- <div class="gtp-banner">
              <div class="gtp-banner-content" id="gtp-banner-block">
                <div id="gtp-banner-graphic">
                  <h3 class="fs-44 fw-700">Goldie's<br /> Top Picks</h3>
                </div>
              </div>
            </div> -->
            <div class="goldies-top-picks-block goldies-top-picks-block-empty">
              <div class="gtp-subscribe-block">
                <div class="gtp-subscribe-block-content">
                  <h3 class="fs-32 fw-600">Goldie is unable to find any perfect fits for you at this time.</h3>
                  <img src="../../../assets/images/top-picks-notfound-icon.svg" alt="">
                  <h4 class="reachout fs-24 fw-400">Reach out to us for more help finding your perfect pair of jeans at
                    team@fitgoldie.com</h4>
                  <div class="doublecheck fs-16">
                    <p>Double check your measurements with our <a href="javascript:void(0);" class="fg-btn-link how-to-measure">How to Measure Guide</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="gtp-submit-retailer-block fs-16">
                <p>If you have a favorite jeans brand, let us know! We'll keep searching for your perfect fit.  <a
                    href="javascript:void(0);" class="fg-btn-link submit-retailer-link">Submit a Retailer</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data () {
      return {

      }
    }
  }
</script>

<style>
</style>
