<template>
  <div class="fg-results-filter-block" :class="{ 'fg-fitler-results-block-disabled': disableFilters }">
    <div class="gs-filter-title d-flex flex-wrap w-100">
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_973_39917)">
          <path
            d="M6.08002 15.8102C5.72002 15.8102 5.36003 15.7202 5.03003 15.5302C4.36003 15.1502 3.96002 14.4702 3.96002 13.7102V10.1402C3.96002 9.88021 3.79002 9.44022 3.58002 9.19022L1.06 6.5402C0.59 6.0802 0.25 5.2802 0.25 4.6702V3.12021C0.25 1.91021 1.16998 0.950195 2.34998 0.950195H11.25C12.41 0.950195 13.35 1.89021 13.35 3.05021V4.53022C13.35 5.31022 12.9 6.1702 12.46 6.6102L9.53 9.2002C9.3 9.39019 9.11 9.8502 9.11 10.1902V13.0902C9.11 13.7802 8.69001 14.5502 8.14001 14.8802L7.21997 15.4802C6.85997 15.7002 6.47002 15.8102 6.08002 15.8102ZM2.34998 2.46021C2.00998 2.46021 1.75 2.75022 1.75 3.13022V4.68021C1.75 4.85021 1.92001 5.28022 2.14001 5.50022L4.71997 8.21021C5.09997 8.6802 5.46997 9.4502 5.46997 10.1402V13.7102C5.46997 14.0102 5.66003 14.1602 5.78003 14.2202C5.94003 14.3102 6.19998 14.3602 6.41998 14.2202L7.34998 13.6202C7.46998 13.5502 7.60999 13.2702 7.60999 13.1002V10.2002C7.60999 9.41019 7.99999 8.54019 8.54999 8.0702L11.43 5.52021C11.61 5.34021 11.85 4.85022 11.85 4.53022V3.05021C11.85 2.72021 11.58 2.4502 11.25 2.4502H2.34998V2.46021Z"
            fill="#0F2C66" />
          <path
            d="M14 22.46H8C2.57 22.46 0.25 20.14 0.25 14.71V11.71C0.25 11.3 0.59 10.96 1 10.96C1.41 10.96 1.75 11.3 1.75 11.71V14.71C1.75 19.32 3.39 20.96 8 20.96H14C18.61 20.96 20.25 19.32 20.25 14.71V8.70995C20.25 5.80995 19.57 4.12994 18.04 3.25994C17.88 3.16994 17.35 2.92996 15.83 2.68996C15.42 2.62996 15.14 2.23994 15.21 1.82994C15.28 1.41994 15.67 1.13995 16.07 1.20995C17.38 1.41995 18.27 1.65994 18.78 1.94994C20.8 3.09994 21.75 5.24995 21.75 8.70995V14.71C21.75 20.14 19.43 22.46 14 22.46Z"
            fill="#0F2C66" />
          <path
            d="M17 13.46H12C11.59 13.46 11.25 13.12 11.25 12.71C11.25 12.3 11.59 11.96 12 11.96H17C17.41 11.96 17.75 12.3 17.75 12.71C17.75 13.12 17.41 13.46 17 13.46Z"
            fill="#0F2C66" />
          <path
            d="M17 17.46H10C9.59 17.46 9.25 17.12 9.25 16.71C9.25 16.3 9.59 15.96 10 15.96H17C17.41 15.96 17.75 16.3 17.75 16.71C17.75 17.12 17.41 17.46 17 17.46Z"
            fill="#0F2C66" />
        </g>
        <defs>
          <clipPath id="clip0_973_39917">
            <rect width="22" height="22" fill="white" transform="translate(0 0.709961)" />
          </clipPath>
        </defs>
      </svg>
      <h4 class="text-uppercase fs-20 fw-800">Filters</h4>
      <a href="javascript:void(0);" class="filter-block-close">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 1L1 21M1 1L21 21" stroke="#0F2C66" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </a>
    </div>
    <a href="javascript:void(0);" class="reset-filters-link" v-if="resetFilterBtn" @click="resetAllFilters">Reset
      Filters</a>
    <div class="gs-filter-block">
      <h5 class="fs-16 fw-800">Brands</h5>
      <div class="gs-filter-single" v-for="brand in brandsFirst" :key="brand._id">
        <div class="form-check">
          <input type="checkbox" :id="brand._id" v-model="selectedBrands" :value="brand._id" @change="appendBrand">
          <label :for="brand._id" class="filter-single-label fs-14">{{ brand.name }}</label>
        </div>
      </div>
      <a href="javascript:void(0);" v-if="brandsSecond.length" class="gs-more-filters-link">+{{ brandsSecond.length }}
        More</a>
      <div v-if="brandsSecond.length" class="gs-filter-more-filters-block">
        <div class="gs-filter-single" v-for="brand in brandsSecond" :key="brand._id">
          <div class="form-check">
            <input type="checkbox" :id="brand._id" v-model="selectedBrands" :value="brand._id" @change="appendBrand">
            <label :for="brand._id" class="filter-single-label fs-14">{{ brand.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="gs-filter-block">
      <h5 class="fs-16 fw-800">Prices</h5>
      <div class="gs-filter-single" v-for="price in pricesFilter" :key="price.name">
        <div class="form-check">
          <input type="checkbox" :id="price.value" v-model="selectedPrices" :value="price.value" @change="appendPrice">
          <label :for="price.value" class="filter-single-label fs-14">{{ price.name }}</label>
        </div>
      </div>
    </div>
    <div class="gs-filter-block">
      <h5 class="fs-16 fw-800">Style</h5>
      <div class="gs-filter-single" v-for="style in styles" :key="style._id">
        <div class="form-check">
          <input type="checkbox" :id="style._id" v-model="selectedStyles" :value="style._id" @change="appendStyle">
          <label :for="style._id" class="filter-single-label fs-14">{{ style.styleName }}</label>
        </div>
      </div>
    </div>
    <div class="gs-filter-block">
      <h5 class="fs-16 fw-800">Rise</h5>
      <div class="gs-filter-single" v-for="rise in riseFilter" :key="rise.name">
        <div class="form-check">
          <input type="checkbox" :id="rise.name" v-model="selectedRise" :value="rise.value" @change="appendRise">
          <label :for="rise.name" class="filter-single-label fs-14">{{ rise.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import axios from "axios";
  import _ from "lodash";
  import $ from "jquery";

  export default {
    props: ["allBrands", "allStyles"],
    data () {
      return {
        brandsFirst: [],
        brandsSecond: [],
        brands: [],
        styles: [],
        prices: [],
        brandChecked: [],
        styleChecked: [],
        priceChecked: [],
        riseChecked : [],
        selectedBrands: [],
        selectedStyles: [],
        selectedPrices: [],
        selectedRise: [],
        resetFilterBtn: false,
        maintainFilters: {},
        disableFilters: false,
        sortApplied: false,
        pricesFilter: [{
          name: "$0 - $49",
          value: "0-49"
        }, {
          name: "$50 - $99",
          value: "50-99"
        }, {
          name: "$100 - $149",
          value: "100-149"
        }, {
          name: "$150+",
          value: "150+"
        }],
        riseFilter: [{
          name: "Low",
          value: "low"
        }, {
          name: "Mid",
          value: "mid"
        }, {
          name: "High",
          value: "high"
        }]
      }
    },
    async mounted() {
      this.selectedBrands = [];
      this.selectedStyles = [];
      this.selectedPrices = [];
      this.selectedRise = [];
      this.maintainFilters = {};

      if (this.allBrands && this.allBrands.length) {
        this.brandsFirst = _.slice(this.allBrands, 0, 4);
        if (this.allBrands.length >= 4) {
          this.brandsSecond = _.slice(this.brands, 4);
        }
      }

      if (this.allStyles && this.allStyles.length) {
        this.styles = this.allStyles;
      }

      // emit from filters
      this.emitter.on("product-filter-disable", async () => {
        // alert();
        this.disableFilters = false;
      });

      this.emitter.on("product-sort-data", (data) => {
        this.sortApplied = data.sortApplied;
      })

      // const allBrands = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/brands/all`);
      // const allStyles = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/styles/all`);

      // this.brands = allBrands.data.data;
      // this.styles = allStyles.data.data;

      // if (this.brands && this.brands.length && this.brands.length >=4) {
      //   firstArr = _.slice(this.brands, 0, 4);
      //   secArr = _.slice(this.brands, 4);
      // }
      // this.brandsFirst = firstArr;
      // this.brandsSecond = secArr;

      // JQUERY STUFF
      $(document).on("click", ".gs-more-filters-link", function () {
        $(this).next('.gs-filter-more-filters-block').show();
        $(this).hide();
      })
      $(document).on("click", ".reset-filters-link", function () {
        $('.gs-filter-single').each(function () {
          $(this).find('.form-check').children('input:checkbox').prop('checked', false);
        })
      })
    },
    methods: {
      appendBrand: function () {
        this.resetFilterBtn = false;
        this.emitter.emit("show-other-matches-by-filter", {});
        if (this.selectedBrands.length || this.selectedStyles.length || this.selectedPrices.length || this.selectedRise.length) {
          this.resetFilterBtn = true;
          this.disableFilters = true;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: true,
              hide: true
            });
          }
        } else {
          this.disableFilters = false;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: false,
              hide: false
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: false,
              hide: false
            });
          }
        }
        this.maintainFilters.brands = this.selectedBrands;
        this.emitter.emit("product-filter-brand-data", this.maintainFilters);
      },
      appendStyle: function () {
        this.resetFilterBtn = false;
        if (this.selectedBrands.length || this.selectedStyles.length || this.selectedPrices.length || this.selectedRise.length) {
          this.resetFilterBtn = true;
          this.disableFilters = true;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: true,
              hide: true
            });
          }
        } else {
          this.disableFilters = false;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: false,
              hide: false
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: false,
              hide: false
            });
          }
        }
        this.maintainFilters.styles = this.selectedStyles;

        this.emitter.emit("product-filter-brand-data", this.maintainFilters);
      },
      appendPrice: function () {
        this.resetFilterBtn = false;
        if (this.selectedBrands.length || this.selectedStyles.length || this.selectedPrices.length || this.selectedRise.length) {
          this.resetFilterBtn = true;
          this.disableFilters = true;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: true,
              hide: true
            });
          }
        } else {
          this.disableFilters = false;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: false,
              hide: false
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: false,
              hide: false
            });
          }
        }
        this.maintainFilters.prices = this.selectedPrices;

        this.emitter.emit("product-filter-brand-data", this.maintainFilters);
      },
      appendRise: function () {
        this.resetFilterBtn = false;
        if (this.selectedBrands.length || this.selectedStyles.length || this.selectedPrices.length || this.selectedRise.length) {
          this.resetFilterBtn = true;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: true,
              hide: true
            });
          }
          this.disableFilters = true;
        } else {
          this.disableFilters = false;
          if (this.sortApplied) {
            this.emitter.emit("top-pick-hide", {
              sortApplied: true,
              filterApplied: false,
              hide: false
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              sortApplied: false,
              filterApplied: false,
              hide: false
            });
          }
        }
        this.maintainFilters.rises = this.selectedRise;

        this.emitter.emit("product-filter-brand-data", this.maintainFilters);
      },
      resetAllFilters: function () {
        this.selectedBrands = [];
        this.selectedStyles = [];
        this.selectedPrices = [];
        this.selectedRise = [];
        this.disableFilters = true;
        this.emitter.emit("product-filter-brand-data", {
          brands: this.selectedBrands,
          styles: this.selectedStyles,
          prices: this.selectedPrices,
          rises: this.selectedRise
        });
        setTimeout(() => {
          this.disableFilters = false;
        }, 1500);
        this.resetFilterBtn = false;
        if (this.sortApplied) {
          this.emitter.emit("top-pick-hide", {
            sortApplied: true,
            filterApplied: false,
            hide: false
          });
        } else {
          this.emitter.emit("top-pick-hide", {
            sortApplied: false,
            filterApplied: false,
            hide: false
          });
        }
      }
    }
  }
</script>

<style>

</style>
