<template>
  <div id="login-form-popup">
    <form @submit.prevent="submitLoginForm">
      <div class="home-banner-form d-flex flex-wrap w-100">
        <div class="text-box">
          <label for="useremailLogin">Email<sup>*</sup></label>
          <input id="useremailLogin" type="email" v-model="email"/>
          <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="text-box">
          <label for="userpasswordLogin">Password<sup>*</sup></label>
          <input id="userpasswordLogin" type="password" v-model="password"/>
          <span v-for="error in v$.password.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>
        <div class="forgot-password-block">
          <a href="javascript:void(0);" class="toggle-link-gold forgot-toggle-link">Forgot Password</a>
        </div>
        <div class="home-form-cta signup-form-cta">
          <button type="submit" class="fg-btn-primary w-100">
            <span>Sign In</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                stroke="#0F2C66" stroke-width="2" />
            </svg>
          </button>
        </div>
        <div class="signin-toggle-block fs-16">
          <span class="text-white">Don't have an account?</span>
          <a href="javascript:void(0);" class="toggle-link-gold signup-toggle-link">Sign Up</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from "jquery";
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers, minLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      password: ""
    }
  },
  validations () {
    return {
      password: {
        required: helpers.withMessage('Password is required.', required),
        minLength: helpers.withMessage('Password should be at least 6 characters long.', minLength(6))
      },
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('Invalid email address.', email),
      }
    }
  },
  methods: {
    submitLoginForm: async function () {
      this.v$.$touch();
      const validateForm = await this.v$.$validate();
      if (!validateForm) return;
      const loginResponse = await this.$store.dispatch('loginUser', {
        email: this.email,
        password: this.password
      });
      if (loginResponse.code === 200) {
        const currentUrl = window.location.href;
        this.$router.push({path: "/"});
        if (currentUrl.includes("?")) {
          $('.popup-signup-login').hide();
        } else {
          this.$router.go();
        }
        // this.$swal({
        //   position: "top",
        //   icon: false,
        //   title: false,
        //   html: "You are now logged in!",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        // setTimeout(() => {this.$router.go();},1700);
        return;
      } else {
        this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: loginResponse.message,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }
  },
  mounted() {
    var self = this;
    $('.popup-signup-close').on('click',function(){
      let currentUrl = window.location.href;
      if (currentUrl.includes("?")) {
        const newUrl = currentUrl.split("?")[0];
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
      self.v$.$reset();
      $('.popup-signup-login').hide();
      $('.popup-measurement').hide();
      $('.popup-submit-retailer').hide();
      $('.popup-feedback').hide();
      $('body').removeClass('noscroll');
    });

    $('.signup-toggle-link').on('click',function(){
      self.v$.$reset();
      $('#login-form-popup,#forgotpassword-form-popup').hide();
      $('.signup-form-popup').show();
    });

    $('.forgot-toggle-link').on('click',function(){
      self.v$.$reset();
      $('.signup-form-popup,#login-form-popup').hide();
      $('#forgotpassword-form-popup').show();
    })

  }
}
</script>

<style>

</style>
