<template>
  <section class="partner-sec-3">
    <div class="container">
      <div class="row">
        <div class="col-12 position-relative">
          <img src="assets/images/star-pattern-horizontal.svg" alt="" class="partner-sec-3-pattern">
          <div class="ps3-content-block">
            <svg width="38" height="58" viewBox="0 0 38 58" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="ps3-icon-1">
              <path
                d="M18.7405 0L19.7375 6.79034C20.9226 14.8614 25.3713 26.9049 34.7983 28.2269L37.0875 28.5479L34.7983 28.869C24.2271 31.0129 20.9226 42.8226 19.7375 50.8937L18.7405 57.684L17.7435 50.8937C16.5584 42.8226 11.7162 29.5489 2.28929 28.2269L0 27.9059L2.28929 27.5848C11.7162 26.2629 16.5584 14.8614 17.7435 6.79035L18.7405 0Z"
                fill="#F2C840" />
              <animate attributeName="opacity" dur="1.5s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1">
              </animate>
            </svg>
            <h2 class="fs-48 fw-600 text-center">{{ sectionThreeTitle }}</h2>
            <div class="ps3-content-intro text-center fs-20" v-html="sectionThreeDesc">
            </div>
            <div class="ps3-graphic-block d-flex flex-wrap w-100">
              <svg width="25" height="38" viewBox="0 0 25 38" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="ps3-icon-2">
                <path
                  d="M12.2129 0L12.8627 4.38492C13.635 9.59688 16.5341 17.3741 22.6775 18.2278L24.1694 18.4351L22.6775 18.6424C15.7885 20.0268 13.635 27.653 12.8627 32.865L12.2129 37.2499L11.5632 32.865C10.7909 27.653 7.63529 19.0814 1.4919 18.2278L0 18.0204L1.4919 17.8131C7.63529 16.9595 10.7909 9.59688 11.5632 4.38492L12.2129 0Z"
                  fill="#F2C840" />
                <animate attributeName="opacity" dur="2s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1">
                </animate>
              </svg>
              <div class="ps3-graphic-block-left">
                <div class="ps3-graphic-text-item">
                  <div class="ps3-title-small fs-16">{{ sectionThreeProblemTitle }}
                  </div>
                  <div class="ps3-graphic-desc fs-24 fw-600" v-html="sectionThreeProblemDes">
                  </div>
                </div>
                <div class="ps3-graphic-text-item">
                  <div class="ps3-title-small fs-16"> {{ sectionThreeSolutionTitle }}
                  </div>
                  <div class="ps3-graphic-desc fs-24 fw-600" v-html="sectionThreeSolutionDes">
                  </div>
                </div>
              </div>
              <div class="ps3-graphic-block-right">
                <!-- <img :src="`${baseUrl}${sectionThreeImage}`" alt=""> -->
                <video id="homevideo" width="100%" autoplay muted loop playsinline>
                  <source :src="`${baseUrl}/admin/uploads/how-to-measure-video/pwu_video.mp4`" type="video/mp4" />
                  <!-- <source src="assets/images/pangea-video-1.mp4" type="video/mp4" /> -->
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['sectionThreeTitle', 'sectionThreeDesc', 'scetionThreeProblemTitle', 'sectionThreeProblemDes', 'sectionThreeSolutionTitle', 'sectionThreeSolutionDes', 'sectionThreeImage', 'sectionThreeProblemTitle'],
  data (){
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  }
}
</script>

<style></style>
