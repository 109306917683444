// import axios from "axios";

export default {
  measurementFromUser(context, payload) {
    // console.log(payload);
    context.commit('setMeasurement', {
      waist: payload.waist,
      hip: payload.hip,
      thigh: payload.thigh,
      inseam: payload.inseam,
      email: payload.email
    });
    localStorage.setItem('waist', payload.waist);
    localStorage.setItem('hip', payload.hip);
    localStorage.setItem('thigh', payload.thigh);
    localStorage.setItem('inseam', payload.inseam);
    localStorage.setItem('email', payload.email);
    localStorage.setItem('measurementType', payload.measurementType);

    return payload;
  }
};
