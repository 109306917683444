<template>
  <section-one :bannerTitle="termsConditions.bannerTitle" :sectionTwoDesc="termsConditions.sectionTwoDesc"></section-one>
</template>

<script>
import $ from "jquery";
import axios from "axios";

import SectionOne from "../components/layout/privacy-policy/SectionOne.vue";

export default {
  components: {
    SectionOne,
  },
  data() {
    return {
      termsConditions: {}
    }
  },
  async mounted() {
    $('body').removeClass('noscroll');
    // call api for how goldie works page
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/terms-conditions`);
    if (response && response.status === 200) {
      const data = response.data.data;
      this.termsConditions = data;
      document.title = `${this.termsConditions.metaTitle || "Fit Goldie"}`;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', `${this.termsConditions.metaDesc || "Fit Goldie"}`);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', `${this.termsConditions.metaTitle || "Fit Goldie"}`);
        newMetaDescription.setAttribute('content', `${this.termsConditions.metaDesc || "Fit Goldie"}`);
        document.head.appendChild(newMetaDescription);
      }

      // console.log("this.termsConditions------------");
      // console.log(this.termsConditions);
    }
  }
}
</script>

<style></style>
