<template>
  <div class="popup-measurement">
    <a href="javascript:void(0);" class="popup-signup-close">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M30 10L10 30M10 10L30 30" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"></path>
      </svg>
    </a>
    <div class="measurement-popup-block d-flex flex-wrap w-100">
      <div class="measurement-popup-left">
        <h3 class="fs-32 fw-600 text-static" style="text-transform: none;">{{ howToMeasure.title }}</h3>
        <span class="fs-24 fs-600">{{ howToMeasure.subTitle }}</span>
        <a :href="howToMeasure.printTextLink" target="_blank">{{ howToMeasure.printText }}</a>
        <div class="measurement-popup-steps">
          <div class="measurement-popup-item d-flex flex-wrap w-100">
            <div class="mp-icon">
              <img :src="`${baseUrl}${howToMeasure.waistIcon}`" alt="">
            </div>
            <div class="mp-text">
              <p>{{ howToMeasure.waistText }}</p>
            </div>
          </div>
          <div class="measurement-popup-item d-flex flex-wrap w-100">
            <div class="mp-icon">
              <img :src="`${baseUrl}${howToMeasure.hipIcon}`" alt="">
            </div>
            <div class="mp-text">
              <p>{{ howToMeasure.hipText }}</p>
            </div>
          </div>
          <div class="measurement-popup-item d-flex flex-wrap w-100">
            <div class="mp-icon">
              <img :src="`${baseUrl}${howToMeasure.thighIcon}`" alt="">
            </div>
            <div class="mp-text">
              <p>{{ howToMeasure.thighText }}</p>
            </div>
          </div>
          <div class="measurement-popup-item d-flex flex-wrap w-100">
            <div class="mp-icon">
              <img :src="`${baseUrl}${howToMeasure.inseamIcon}`" alt="">
            </div>
            <div class="mp-text">
              <p>{{ howToMeasure.inseamText }}</p>
            </div>
          </div>
        </div>
        <div class="fs-24 fw-400">
          <p>{{ howToMeasure.footerText }}</p>
        </div>
      </div>
      <div class="measurement-popup-right">
        <!-- <img :src="`${baseUrl}${howToMeasure.measurementThumbnail}`" alt="" class="img-fluid"> -->
        <video controls muted style="width:100%;height:auto;" id="measurement-video">
          <!-- <source src="https://demo.webshowcase-india.com/fitgoldie/assets/images/how-to-measure.mp4" type="video/mp4"> -->
          <source :src="`${baseUrl}/admin/uploads/how-to-measure-video/htm_video.mp4#t=2`" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        howToMeasure: {},
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    async mounted () {
      const howToMeasure = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/common/settings?type=measure-settings`);
      const response = howToMeasure.data.data;
      this.howToMeasure = response;
    }
  }
</script>

<style></style>
