  <template>
  <section class="hiw-sec-2 sec-nav-blur">
    <img src="../../../assets/images/about-goldie-bg-pattern-left.svg" alt="" class="hiw-sec-2-bg-left">
    <img src="../../../assets/images/about-goldie-bg-pattern-right.svg" alt="" class="hiw-sec-2-bg-right">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="hiw-intro d-flex flex-wrap w-100">
            <div class="hiw-intro-left">
              <h2 class="fs-48 fw-600">{{ sectionTwoTitle }}</h2>
            </div>
            <div class="hiw-intro-left fs-18" v-html="sectionTwoDesc">
            </div>
          </div>
          <div class="hiw-steps-block" ref="hiwStepsBlock">
            <div class="hiw-steps-progress">
							<div class="hiw-steps-handle"></div>
						</div>
            <div class="hiw-step-item">
              <div class="hiw-item-content d-flex flex-wrap w-100">
                <div class="hiw-item-content-left">
                  <!-- <img :src="`${baseUrl}${stepOneImg}`" alt="" class="img-fluid"> -->
                  <div id="hiw-icon-1"></div>
                </div>
                <div class="hiw-item-center">
                  <div class="hiw-item-divider"></div>
                </div>
                <div class="hiw-item-content-right">
                  <div class="hiw-step-title d-flex flex-wrap w-100">
                    <h3 class="fs-24 fw-600">{{ stepOneTitle }}</h3>
                    <div class="hiw-step-no">
                      <span>Step 01</span>
                    </div>
                  </div>
                  <div class="hiw-right-desc fs-18" v-html="stepOneDesc">
                  </div>
                  <div class="hiw-right-link" v-if="stepOneLink == 'none' ? false : true">
                    <a href="javascript:void(0);" class="fg-btn-link" :class="stepOneLink == 'measurement' ? 'how-to-measure' : stepOneLink == 'form' ? 'share-feedback-link' : ''">
                      <span>{{ stepOneLink == "measurement" ? "How to Measure" : stepOneLink == "none" ? "" : "Submit Form" }}</span>
                      <svg v-if="stepOneLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                          stroke="#1F5CD7" stroke-width="2" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="hiw-step-item">
              <div class="hiw-item-content d-flex flex-wrap w-100">
                <div class="hiw-item-content-left">
                  <div class="hiw-step-title d-flex flex-wrap w-100">
                    <h3 class="fs-24 fw-600">{{ stepTwoTitle }}</h3>
                    <div class="hiw-step-no">
                      <span>Step 02</span>
                    </div>
                  </div>
                  <div class="hiw-right-desc fs-18" v-html="stepTwoDesc">
                  </div>
                  <div class="hiw-right-link" v-if="stepTwoLink == 'none' ? false : true">
                    <a href="javascript:void(0);" class="fg-btn-link" :class="stepTwoLink == 'measurement' ? 'how-to-measure' : stepTwoLink == 'form' ? 'share-feedback-link' : ''">
                      <span>{{ stepTwoLink == "measurement" ? "How to Measure" : stepTwoLink == "none" ? "" : "Submit Form" }}</span>
                      <svg v-if="stepTwoLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                          stroke="#1F5CD7" stroke-width="2" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="hiw-item-center">
                  <div class="hiw-item-divider "></div>
                </div>
                <div class="hiw-item-content-right">
                  <!-- <img :src="`${baseUrl}${stepTwoImg}`" alt="" class="img-fluid"> -->
                  <div id="hiw-icon-2"></div>
                </div>
              </div>
            </div>
            <div class="hiw-step-item">
              <div class="hiw-item-content d-flex flex-wrap w-100">
                <div class="hiw-item-content-left">
                  <!-- <img :src="`${baseUrl}${stepThreeImg}`" alt="" class="img-fluid"> -->
                  <div id="hiw-icon-3"></div>
                </div>
                <div class="hiw-item-center">
                  <div class="hiw-item-divider"></div>
                </div>
                <div class="hiw-item-content-right">
                  <div class="hiw-step-title d-flex flex-wrap w-100">
                    <h3 class="fs-24 fw-600">{{ stepThreeTitle }}</h3>
                    <div class="hiw-step-no">
                      <span>Step 03</span>
                    </div>
                  </div>
                  <div class="hiw-right-desc fs-18" v-html="stepThreeDesc">
                  </div>
                  <div class="hiw-right-link" v-if="stepThreeLink == 'none' ? false : true">
                    <a href="javascript:void(0);" class="fg-btn-link" :class="stepThreeLink == 'measurement' ? 'how-to-measure' : stepThreeLink == 'form' ? 'share-feedback-link' : ''">
                      <span>{{ stepThreeLink == "measurement" ? "How to Measure" : stepThreeLink == "none" ? "" : "Submit Form" }}</span>
                      <svg v-if="stepThreeLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                          stroke="#1F5CD7" stroke-width="2" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="hiw-step-item">
              <div class="hiw-item-content d-flex flex-wrap w-100">
                <div class="hiw-item-content-left">
                  <div class="hiw-step-title d-flex flex-wrap w-100">
                      <h3 class="fs-24 fw-600">{{ stepFourTitle }}</h3>
                      <div class="hiw-step-no">
                        <span>Step 04</span>
                      </div>
                  </div>
                  <div class="hiw-right-desc fs-18" v-html="stepFourDesc">
                  </div>
                  <div class="hiw-right-link" v-if="stepFourLink == 'none' ? false : true">
                    <a href="javascript:void(0);" class="fg-btn-link" :class="stepFourLink == 'measurement' ? 'how-to-measure' : stepFourLink == 'form' ? 'share-feedback-link' : ''">
                      <span>{{ stepFourLink == "measurement" ? "How to Measure" : stepFourLink == "none" ? "" : "Submit Form" }}</span>
                      <svg v-if="stepFourLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                          stroke="#1F5CD7" stroke-width="2" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="hiw-item-center">
                  <div class="hiw-item-divider"></div>
                </div>
                <div class="hiw-item-content-right">
                  <div id="hiw-icon-4"></div>
                  <!-- <img :src="`${baseUrl}${stepFourImg}`" alt="" class="img-fluid"> -->
                </div>
              </div>
            </div>
          </div>
          <div class="hiw-steps-cta" v-if="btnSwitch">
            <a href="/products" class="fg-btn-primary fg-btn-primary-blue">
              <span>Get Started</span>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492" stroke="white" stroke-width="2"></path>
              </svg>
            </a>
          </div>
          <div class="hiw-steps-cta" v-else>
            <a href="javascript:void(0);" class="fg-btn-primary fg-btn-primary-blue signup-login-link">
              <span>Get Started</span>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492" stroke="white" stroke-width="2"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  </template>

  <script>
  import $ from "jquery";
  import ScrollMagic from "scrollmagic"; 
  import lottie from 'lottie-web';

  export default {
    props: ["sectionTwoTitle", "sectionTwoDesc", "stepOneImg", "stepOneTitle", "stepOneDesc", "stepOneLink", "stepTwoImg", "stepTwoTitle", "stepTwoDesc", "stepTwoLink", "stepThreeImg", "stepThreeTitle", "stepThreeDesc", "stepThreeLink", "stepFourImg", "stepFourTitle", "stepFourDesc", "stepFourLink"],
    data () {
      return {
        btnSwitch: false,
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    mounted() {
      /*How it works step 1 animation scrollmagic*/
      // Load Lottie animation
      var step_animation_1 = lottie.loadAnimation({
          container: document.getElementById('hiw-icon-1'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/hiw-icon-1.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_step_animation_1 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#hiw-icon-1', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.5 // Start animation when trigger is 50% down the viewport
      })
      .addTo(controller_step_animation_1)
      .on('enter', function () {
          // Reset and play the animation when entering the scene
          step_animation_1.goToAndStop(0, true);
          step_animation_1.play();
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          step_animation_1.goToAndStop(step_animation_1.totalFrames - 1, true);
      });
    /*How it works step 1 animation scrollmagic*/
    /*How it works step 2 animation scrollmagic*/
      // Load Lottie animation
      var step_animation_2 = lottie.loadAnimation({
          container: document.getElementById('hiw-icon-2'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/hiw-icon-2.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_step_animation_2 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#hiw-icon-2', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.5 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_step_animation_2)
      .on('enter', function () {
          // Reset and play the animation when entering the scene
          step_animation_2.goToAndStop(0, true);
          step_animation_2.play();
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          step_animation_2.goToAndStop(step_animation_2.totalFrames - 1, true);
      });
    /*How it works step 2 animation scrollmagic*/
    /*How it works step 3 animation scrollmagic*/
      // Load Lottie animation
      var step_animation_3 = lottie.loadAnimation({
          container: document.getElementById('hiw-icon-3'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/hiw-icon-3.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_step_animation_3 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#hiw-icon-3', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.5 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_step_animation_3)
      .on('enter', function () {
          // Reset and play the animation when entering the scene
          step_animation_3.goToAndStop(0, true);
          step_animation_3.play();
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          step_animation_3.goToAndStop(step_animation_3.totalFrames - 1, true);
      });
    /*How it works step 3 animation scrollmagic*/
    /*How it works step 4 animation scrollmagic*/
      // Load Lottie animation
      var step_animation_4 = lottie.loadAnimation({
          container: document.getElementById('hiw-icon-4'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/hiw-icon-4.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller_step_animation_4 = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#hiw-icon-4', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.5 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller_step_animation_4)
      // .on('enter', function () {
      //     // Add a delay of 5 seconds before starting the animation
      //     setTimeout(function () {
      //         step_animation_4.play();
      //     }, 0); // 5000 milliseconds = 5 seconds
      // })
      // .on('leave', function () {
      //     // Hold the end state of the animation when leaving the scene
      //     step_animation_4.goToAndStop(step_animation_4.totalFrames - 1, true);
      // });
      .on('enter', function () {
          // Reset and play the animation when entering the scene
          step_animation_4.goToAndStop(0, true);
          step_animation_4.play();
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          step_animation_4.goToAndStop(step_animation_4.totalFrames - 1, true);
      });
    /*How it works step 4 animation scrollmagic*/
      // jquery stuff
      $(document).on('click', '.how-to-measure', function(){
        $('.popup-measurement').fadeIn();
        $('body').addClass('noscroll');
      })

      $('.popup-signup-close').on('click',function(){
        $('.popup-signup-login').hide();
        $('.popup-measurement').hide();
        $('.popup-submit-retailer').hide();
        $('.popup-feedback').hide();
        $('body').removeClass('noscroll');
      })

      let isLoggedIn = this.$store.getters.isAuth;
      if (isLoggedIn) {
        this.btnSwitch = true;
      } else {
        this.btnSwitch = false;
      }
    }
  }
  </script>

  <style></style>
