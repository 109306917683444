<template>
  <div class="popup-feedback">
    <a href="javascript:void(0);" class="popup-signup-close">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M30 10L10 30M10 10L30 30" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"></path>
      </svg>
    </a>
    <div class="feedback-content-block">
      <h2 class="fs-32 fw-600">What do you think about Goldie?</h2>
      <div class="fs-16 fcb-intro">
        <p>Now that you've experienced Goldie, we need your feedback!</p>
      </div>
      <div class="feedback-form-fields-block">
        <div class="feedback-form-item-block">
          <div class="fs-16">How did you hear about Goldie?</div>
          <div class="feedback-form-checkbox-block">
            <label class="container-checkbox-fb">Search Engine
              <input type="checkbox" value="Search Engine" v-model="hearAbout">
              <span class="checkmark"></span>
            </label>
            <label class="container-checkbox-fb">Social Media
              <input type="checkbox" value="Social Media" v-model="hearAbout">
              <span class="checkmark"></span>
            </label>
            <label class="container-checkbox-fb">News
              <input type="checkbox" value="News" v-model="hearAbout">
              <span class="checkmark"></span>
            </label>
            <label class="container-checkbox-fb">Friend
              <input type="checkbox" value="Friend" v-model="hearAbout">
              <span class="checkmark"></span>
            </label>
            <label class="container-checkbox-fb">Other
              <input type="checkbox" value="Other" v-model="hearAbout">
              <span class="checkmark"></span>
            </label>
          </div>
          <textarea name="" id="" class="feedback-msg-textarea" v-if="hearAbout.includes('Other')"
            placeholder="Write your message here" v-model="hearMsg"></textarea>
        </div>
        <div class="feedback-form-item-block">
          <div class="fs-16">Did you try any of your matches?</div>
          <div class="feedback-form-checkbox-block">
            <label class="container-radio-fb">Yes
              <input type="radio" checked="checked" name="radio" value=1 v-model="tryMatch">
              <span class="checkmark"></span>
            </label>
            <label class="container-radio-fb">No
              <input type="radio" name="radio" value=0 v-model="tryMatch">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="fs-16">How well did Goldie calculate your size?</div>
        <div class="radio-check-block">
          <input type="radio" id="btn1" name="number" value="1" v-model="feedbackNo">
          <label for="btn1">1</label>
          <input type="radio" id="btn2" name="number" value="2" v-model="feedbackNo">
          <label for="btn2">2</label>
          <input type="radio" id="btn3" name="number" value="3" v-model="feedbackNo">
          <label for="btn3">3</label>
          <input type="radio" id="btn4" name="number" value="4" v-model="feedbackNo">
          <label for="btn4">4</label>
          <input type="radio" id="btn5" name="number" value="5" v-model="feedbackNo">
          <label for="btn5">5</label>
          <input type="radio" id="btn6" name="number" value="6" v-model="feedbackNo">
          <label for="btn6">6</label>
          <input type="radio" id="btn7" name="number" value="7" v-model="feedbackNo">
          <label for="btn7">7</label>
          <input type="radio" id="btn8" name="number" value="8" v-model="feedbackNo">
          <label for="btn8">8</label>
          <input type="radio" id="btn9" name="number" value="9" v-model="feedbackNo">
          <label for="btn9">9</label>
          <input type="radio" id="btn10" name="number" value="10" v-model="feedbackNo">
          <label for="btn10">10</label>
        </div>
        <div class="radio-check-text d-flex flex-wrap w-100">
          <span>Not likely at all</span>
          <span>Extremely likely</span>
        </div>
        <span v-for="error in v$.feedbackNo.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        <div class="feedback-message-block position-relative">
          <h4 class="fs-16 fw-400 text-static">Tell us how we can improve</h4>
          <textarea name="" id="" class="feedback-msg-textarea" placeholder="Write your message here"
            v-model="message"></textarea>
          <span v-for="error in v$.message.$errors" :key="error.uid" class="label-error label-error-white">{{ error.$message }}</span>
        </div>
      </div>
      <div class="feedback-form-cta d-flex flex-wrap w-100">
        <a href="javascript:void(0);" class="close-feedback-form">Skip</a>
        <button type="submit" class="fg-btn-primary fg-feedback-submit" @click="submitFeedback">
          <span>Submit</span>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
              stroke="#0F2C66" stroke-width="2"></path>
          </svg>
        </button>
      </div>

    </div>
    <div class="feedback-thankyou-block" style="display:none;">
      <h2 class="fs-32 fw-600">Thank you for your feedback!</h2>
      <div class="feedback-thankyou-text fs-16">
        <p>Thanks for your feedback, please reach out to <a href="mailto:team@fitgoldie.com">team@fitgoldie.com</a>
          for any further comments. Try us again another time, we’ll be happy to rejoin the quest and find your perfect
          jeans in the future!</p>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";

  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';

  export default {
    data () {
      return {
        v$: useVuelidate(),
        feedbackNo: null,
        message: "",
        hearAbout: [],
        hearMsg: "",
        tryMatch: 1,
        showShortMsgBox: false
      }
    },
    validations () {
      return {
        feedbackNo: {
          required: helpers.withMessage('Please give a rating.', required)
        },
        message: {
          required: helpers.withMessage('Message is required.', required)
        }
      }
    },
    computed: {
      isAuth: function () {
        return this.$store.getters.isAuth;
      },
      getToken: function () {
        return this.$store.getters.getToken;
      }
    },
    methods: {
      submitFeedback: async function () {
        let urlParams = new URLSearchParams(window.location.search);
        let recog = urlParams.get('recog');
        // check whether use is logged in or not
        if (this.isAuth && this.getToken) {
          this.v$.$touch();
          const validateForm = await this.v$.$validate();
          if (!validateForm) return;
          const feedbackResponse = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/save`, {
            star: this.feedbackNo,
            feedbackMessage: this.message,
            hearAbout: this.hearAbout,
            hearMsg: this.hearMsg,
            tryMatch: this.tryMatch
          }, {
            headers: {
              Authorization: "Bearer " + this.getToken
            }
          });
          if (feedbackResponse) {
            const currentUrl = window.location.href;
            if (currentUrl.includes("?")) {
              // this.$swal({
              //   position: "top",
              //   icon: false,
              //   title: false,
              //   html: "Thank you for your feedback.",
              //   showConfirmButton: false,
              //   timer: 2100,
              // });
              setTimeout(() => {
                const newUrl = currentUrl.split("?")[0];
                window.history.pushState({ path: newUrl }, '', newUrl);
                window.location.reload();
              }, 2000);
            }
            $('.feedback-content-block').hide();
            $('.feedback-thankyou-block').fadeIn();
            return;
          }
        } else if (recog) {
          this.v$.$touch();
          const validateForm = await this.v$.$validate();
          if (!validateForm) return;
          const feedbackResponse = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/save/from/mail?recog=${recog}`, {
            star: this.feedbackNo,
            feedbackMessage: this.message,
            hearAbout: this.hearAbout,
            hearMsg: this.hearMsg,
            tryMatch: this.tryMatch
          }, {});
          if (feedbackResponse) {
            const currentUrl = window.location.href;
            if (currentUrl.includes("?")) {
              // this.$swal({
              //   position: "top",
              //   icon: false,
              //   title: false,
              //   html: "Thank you for your feedback.",
              //   showConfirmButton: false,
              //   timer: 2100,
              // });
              setTimeout(() => {
                const newUrl = currentUrl.split("?")[0];
                window.history.pushState({ path: newUrl }, '', newUrl);
                window.location.reload();
              }, 2000);
            }
            $('.feedback-content-block').hide();
            $('.feedback-thankyou-block').fadeIn();
            return;
          }
        } else {
          $('.popup-signup-login').css({'display': 'flex'});
          $('.signup-form-popup').hide();
          $('#login-form-popup').show();
          let currentUrl = window.location.href;
          const newUrl = currentUrl + '?type_source=feedback';
          window.history.pushState({ path: newUrl }, '', newUrl);
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please login to submit feedback.",
            showConfirmButton: false,
            timer: 2100,
          });
          return;
        }
      }
    },
    mounted() {
      // JQuery stuff
      $('.popup-signup-close').on('click',function(){
        const currentUrl = window.location.href;
        if (currentUrl.includes("?")) {
          const newUrl = currentUrl.split("?")[0];
          window.history.pushState({ path: newUrl }, '', newUrl);
          window.location.reload();
        }
        $('.popup-signup-login').hide();
        $('.popup-measurement').hide();
        $('.popup-submit-retailer').hide();
        $('.popup-feedback').hide();
        $('body').removeClass('noscroll');
      })

      // $(document).on('click', '.fg-feedback-submit', function(){
      //   $('.feedback-content-block').hide();
      //   $('.feedback-thankyou-block').fadeIn();
      // })

      $('.close-feedback-form').on('click',function(){
        const currentUrl = window.location.href;
        if (currentUrl.includes("?")) {
          const newUrl = currentUrl.split("?")[0];
          window.history.pushState({ path: newUrl }, '', newUrl);
          window.location.reload();
        }
        $('.popup-feedback').hide();
        $('body').removeClass('noscroll');
      })
    }
  }
</script>

<style>

</style>
