<template>
  <div class="popup-signup-login">
      <svg width="42" height="49" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg" class="about-banner-icon login-banner-icon-1">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2121 8.81354L21.1392 11.1791C20.9237 18.1736 16.2568 23.7831 10.4405 24.0388L8.30521 24.1326L10.4405 24.2265C16.2568 24.4822 20.9237 30.0917 21.1392 37.0862L21.2121 39.4518L21.285 37.0862C21.5006 30.0916 26.1674 24.4822 31.9837 24.2265L34.119 24.1326L31.9837 24.0388C26.1674 23.7831 21.5006 18.1736 21.285 11.1791L21.2121 8.81354ZM20.9413 0.000976565L21.483 0.000976562L21.8267 11.1549C22.0318 17.8083 26.471 23.1442 32.0035 23.3873L41.5492 23.8069V24.4584L32.0035 24.878C26.471 25.1211 22.0318 30.457 21.8267 37.1103L21.483 48.2643H20.9413L20.5975 37.1104C20.3925 30.457 15.9533 25.1211 10.4207 24.878L0.875 24.4584L0.875 23.8069L10.4207 23.3873C15.9533 23.1442 20.3925 17.8083 20.5975 11.1549L20.9413 0.000976565Z" fill="white"/>
        <animate attributeName="opacity" dur="1.5s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1"></animate>
      </svg>
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="about-banner-icon login-banner-icon-2">
        <path d="M5.67204 0.998659C5.85357 -0.331586 7.43819 -0.331584 7.61972 0.998661L8.18123 5.11343L11.5902 5.7912C12.6923 6.01031 12.6923 7.92301 11.5902 8.14212L8.18123 8.81989L7.61972 12.9347C7.43819 14.2649 5.85357 14.2649 5.67204 12.9347L5.11053 8.81989L1.70155 8.14212C0.599481 7.92301 0.599483 6.01031 1.70155 5.7912L5.11053 5.11343L5.67204 0.998659Z" fill="#0F2C66"/>
        <animate attributeName="opacity" dur="2s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1"></animate>
      </svg>
      <a href="javascript:void(0);" class="popup-signup-close">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M30 10L10 30M10 10L30 30" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
      </a>
    <div class="signup-login-content">
      <div class="login-signup-logo">
        <img src="../../../assets/images/logo-fitgoldie.svg" alt="" class="login-signup-logo-img">
      </div>
      <h3 class="login-signup-title fs-28 fw-700 text-white">Start Your Journey</h3>
      <the-signup></the-signup>
      <the-login></the-login>
      <forgot-password></forgot-password>
    </div>
  </div>
</template>

<script>
  import TheSignup from "./signup/TheSignup.vue";
  import TheLogin from "./login/TheLogin.vue";
  import ForgotPassword from "./forgotPassword/ForgotPassword.vue";

  export default {
    components: {
      TheSignup,
      TheLogin,
      ForgotPassword
    },
    data() {
      return {};
    }
  }
</script>

<style></style>