export default {
  getMeasurement(state) {
    return state.measurement;
  },
  getMeasurementFromLocal(state) {
    console.log(state.measurement);
    let waist = localStorage.getItem('waist');
    let hip = localStorage.getItem('hip');
    let thigh = localStorage.getItem('thigh');
    let inseam = localStorage.getItem('inseam');
    let email = localStorage.getItem('email');
    let measurementType = localStorage.getItem('measurementType');

    return {
      waist,
      hip,
      thigh,
      inseam,
      email,
      measurementType
    }
  }
};
