<template>
<!-- ./assets/logo.png -->
<header class="fg-navigation">
  <nav class="top-nav navbar navbar-expand-lg menus">
    <div class="container">
      <!-- <router-link to="/" class="navbar-brand">
        <img :src="`${baseUrl}${mainLogo}`" alt="">
      </router-link> -->
      <a href="/" class="navbar-brand">
        <img :src="`${baseUrl}${mainLogo}`" alt="">
      </a>
      <button class="navbar-toggler hamburger" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
        aria-label="Toggle navigation" id="hamburger-line">
        <span class="navbar-toggler-icon line"></span>
        <span class="navbar-toggler-icon line"></span>
        <span class="navbar-toggler-icon line"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav navbar-menu">
          <li v-if="isLoggedIn" class="nav-item nav-item-mobile">
            <a class="nav-link" href="#/"><strong>Welcome {{ loggedInUserName }}</strong></a>
          </li>
          <li v-if="isLoggedIn" class="nav-item nav-item-mobile">
            <a class="nav-link" href="/products"><strong>My Matches</strong></a>
          </li>
          <li class="nav-item">
            <!-- <router-link to="/about" class="nav-link" href="javascript:void(0);">About Us</router-link> -->
            <a class="nav-link" href="/about">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/how-goldie-works">How Goldie Works</a>
          </li>
          <li class="nav-item">
           <!--  <router-link to="/partner-with-us" class="nav-link" href="javascript:void(0);">Partner with Us</router-link> -->
           <a class="nav-link" href="/partner-with-us">Partner with Us</a>
          </li>
          <li v-if="isLoggedIn" class="nav-item nav-item-mobile">
						<router-link to="/my-profile" href="javascript:void(0);" class="nav-link"><strong>My Profile</strong></router-link>
					</li>
          <li v-if="isLoggedIn" class="nav-item nav-item-mobile">
            <a class="nav-link" href="javascript:void(0);" @click="logoutUser"><strong>Sign Out</strong></a>
          </li>
          <li v-else class="nav-item nav-item-mobile">
            <a class="nav-link nav-login-link custom-login" href="javascript:void(0);"><strong>Login</strong></a>
          </li>
        </ul>
        <ul v-if="isLoggedIn" class="navbar-nav navbar-login">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- <img src="../../assets/images/nav-user-thumbnail.jpg" alt="" class="user-thumbnail"> -->
              <span class="user-initial">{{ loggedInUserName ? loggedInUserName.slice(0, 1) : "" }}</span>
              {{ loggedInUserName }}
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li class="dropdown-item">
                <a href="/my-favorites">My Favorites</a>
              </li>
              <li class="dropdown-item">
                <a href="/products">My Matches</a>
              </li>
              <li class="dropdown-item">
                <router-link to="/my-profile" href="javascript:void(0);">My Profile</router-link>
              </li>
              <!-- <li><a class="dropdown-item" href="javascript:void(0);">My Profile</a></li> -->
              <li><a class="dropdown-item" href="javascript:void(0);" @click="logoutUser">Sign Out</a></li>
            </ul>
          </li>
        </ul>
        <ul v-else class="navbar-nav navbar-login">
          <li class="nav-item">
            <a class="nav-link nav-login-link custom-login" href="javascript:void(0);">Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link signup-login-link custom-signup" href="javascript:void(0);">Sign Up</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
</template>

<script>
import axios from "axios";
export default {
  data () {
    return {
      mainLogo: "",
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isAuth;
    },
    loggedInUserName: function () {
      return this.$store.getters.getName
    }
  },
  methods: {
    logoutUser: function () {
      this.$store.dispatch('logoutUser');
      this.$router.go();
    }
  },
  async mounted() {
    // Call store for header logo
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/common/settings?type=footer`);

    if (response && response.status === 200 && response.data.code == 200) {
      const data = response.data.data;
      this.mainLogo = data.mainLogo;
      return;
    }
  }
}
</script>

<style></style>
