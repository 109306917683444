<template>
  <section class="account-sec-top">
    <div class="container">
      <div class="row">
        <div class="col-12">&nbsp;</div>
      </div>
    </div>
  </section>
  <section class="sec-404 sec-nav-blur">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="notfound-block">
            <div class="notfound-content text-black">
              <img src="../assets/images/image-404.png" alt="">
              <h1 class="error-message-title fs-40 fw-700">Error 404</h1>
              <div class="error-message-subtitle fs-40 fw-400">
                <p>Page not found!</p>
              </div>
              <div class="error-message-description fs-16">
                <p>Uh oh, we can't seem to find the page you are looking for.
                  <br>
                  Try going back to the previous page or go back to home.
                </p>
              </div>
              <div class="error-message-cta">
                <a href="/" class="fg-btn-primary fg-btn-primary-blue">
                  <span>Go Back Home</span>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                      stroke="white" stroke-width="2"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
</script>

<style>
</style>
