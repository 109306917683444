<template>
    <section class="account-sec-top legal-sec-top">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h1 class="fs-40 fw-400 text-white legal-page-title">{{ bannerTitle }}</h1>
				</div>
			</div>
		</div>
	</section>
	<section class="legal-sec-1 sec-nav-blur">
		<div class="container">
			<div class="row">
				<div class="col-12">
                    <div class="legal-content-block" v-html="sectionTwoDesc">
                    </div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
    export default {
    props: ['bannerTitle', 'sectionTwoDesc'],
    data () {
      return {

      }
    }
  }
</script>

<style>
</style>