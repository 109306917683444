<template>
  <section class="faq-sec">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="faq-block">
            <h2 class="faq-block-title fs-48 fw-400 text-center">{{ faqTitle }}</h2>
            <div class="accordion accordion-flush" id="faq-accordion">
              <div class="accordion-item" v-for="(faqsArr, i) in faqsArrs" :key="faqsArr._id">
                <h2 class="accordion-header">
                  <!-- <button class="accordion-button" :class="i == 0 ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${i}`"
                    aria-expanded="true" :aria-controls="`#collapse${i}`"> -->
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${i}`"
                    aria-expanded="false" :aria-controls="`#collapse${i}`">
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="arrow-icon">
                      <path d="M8 12.0586L16 20.0586L24 12.0586" stroke="#1F5CD7" stroke-width="3"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>{{ faqsArr.question }}</span>
                  </button>
                </h2>
                <div :id="`collapse${i}`" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body" v-html="faqsArr.answer">
                  </div>
                </div>
              </div>
              <!-- <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="arrow-icon">
                      <path d="M8 12.0586L16 20.0586L24 12.0586" stroke="#1F5CD7" stroke-width="3"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>What if I don't have any matches?</span>
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the second item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"
                      class="arrow-icon">
                      <path d="M8 12.0586L16 20.0586L24 12.0586" stroke="#1F5CD7" stroke-width="3"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>What if my matches are not in stock?</span>
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                    collapse plugin adds the appropriate classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                    modify any of this with custom CSS or overriding our default variables. It's also worth noting that
                    just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit
                    overflow.
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import $ from "jquery";

  export default {
    props: ["faqTitle", "faqsArrs"],
    data () {
      return {

      }
    },
    mounted() {
      setTimeout(() => {
        $('.how-to-measure').on('click', function(){
          $('.popup-measurement').fadeIn();
          $('body').addClass('noscroll');
        });
        $('.submit-retailer-link').on('click', function(){
          $('.popup-submit-retailer').css({'display':'flex'});
          $('body').addClass('noscroll');
        });
        $('.popup-signup-close').on('click',function(){
          $('.popup-signup-login').hide();
          $('.popup-measurement').hide();
          $('.popup-submit-retailer').hide();
          $('.popup-feedback').hide();
          $('body').removeClass('noscroll');
        });
      }, 1000);
    }
  }
</script>

<style></style>
