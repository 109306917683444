<template>
  <section class="partner-sec-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="ps4-content-block d-flex flex-wrap w-100">
            <div class="ps4-content-left">
              <h2 class="fs-48 fw-600">{{ sectionFourTitle }}</h2>
            </div>
            <div class="ps4-content-right">
              <div class="ps4-text fs-20" v-html="sectionFourDesc">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['sectionFourTitle', 'sectionFourDesc'],
  data () {
    return {

    }
  }
}
</script>

<style></style>