<template>
  <div class="measurements-form-overlay"></div>
  <section-one
  :bannerTitle="partnerWithUs.bannerTitle"
  :bannerImage="partnerWithUs.bannerImage"
  ></section-one>
  <section-two
  :sectionTwoTitle="partnerWithUs.pageName"
  :sectionTwoText="partnerWithUs.sectionTwoText"
  :sectionTwoFormText="partnerWithUs.sectionTwoFormText"
  :sectionTwoTextThree="partnerWithUs.sectionTwoTextThree"
  ></section-two>
  <section-three
  :sectionThreeTitle="partnerWithUs.sectionThreeTitle"
  :sectionThreeDesc="partnerWithUs.sectionThreeDesc"
  :sectionThreeProblemTitle="partnerWithUs.sectionThreeProblemTitle"
  :sectionThreeProblemDes="partnerWithUs.sectionThreeProblemDesc"
  :sectionThreeSolutionTitle="partnerWithUs.sectionThreeSolutionTitle"
  :sectionThreeSolutionDes="partnerWithUs.sectionThreeSolutionDesc"
  :sectionThreeImage="partnerWithUs.sectionThreeImage"
  ></section-three>
  <section-four
  :sectionFourTitle="partnerWithUs.sectionFourTitle"
  :sectionFourDesc="partnerWithUs.sectionFourDesc"
  ></section-four>
  <measurement-block></measurement-block>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";
  import { gsap, ScrollTrigger, TimelineMax } from "gsap/all";
  // import lottie from 'lottie-web';
  //import bodymovin from 'bodymovin';

  import SectionOne from "../components/layout/partner-with-us/SectionOne.vue";
  import SectionTwo from "../components/layout/partner-with-us/SectionTwo.vue";
  import SectionThree from "../components/layout/partner-with-us/SectionThree.vue";
  import SectionFour from "../components/layout/partner-with-us/SectionFour.vue";
  import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";

  export default {
    components: {
      SectionOne,
      SectionTwo,
      SectionThree,
      SectionFour,
      MeasurementBlock
    },
    data () {
      return {
        partnerWithUs: {}
      }
    },
    async mounted() {
      $('body').removeClass('noscroll');
      gsap.registerPlugin(ScrollTrigger);
      gsap.registerPlugin(TimelineMax);

      // measurment popup
      // $('.measurement-block-onscroll .measurement-btn').on('click',function(){
      //   $('.mb-os-form').fadeToggle(100);
      //   /* $('body').addClass('noscroll'); */
      //   if($('.measurements-form-overlay').is(':visible')){
      //     $('.measurements-form-overlay').fadeOut();
      //   }else{
      //     $('.measurements-form-overlay').fadeIn();
      //   }
      //   if($('body').hasClass('noscroll')){
      //     $('body').removeClass('noscroll')
      //   }else{
      //     $('body').addClass('noscroll')
      //   }
      // });

      $(document).ready(function () {        
        $('.accordion-button').on('click', function () {
          $(this).children('.arrow-icon').toggleClass('rotate');
        });

        /*Measurement button form toggle*/
      $('.measurement-btn').on('click', function () {
        $('.measurement-btn .arrow-icon').toggleClass('rotate');
      })

      // Define onStart function
      function onReverseCompleteFunction() {
        $('.measurement-btn .arrow-icon').removeClass('rotate');
      }
      function onStartFunction() {
        $('.measurement-btn .arrow-icon').addClass('rotate');
      }
      document.querySelector(".measurement-btn").addEventListener("click", animateIt);

      var tl = new TimelineMax();
      tl.to(".search-measurements-block", 0.1, {
        // y: 0,
        display: 'flex'
      })
        .to(".measurement-btn-block", 0.1, {
          marginTop: 0,
          onStart: onStartFunction,// Assign onStart callback function
          onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
        }, "<");
      tl.reverse(-1)
      tl.reversed(true);

      function animateIt() {
        tl.reversed(!tl.reversed());
      }
      /*Measurement button form toggle*/
      /*Measurements form toggle onscroll*/
      $(window).bind('scroll', function() {
        var scrollsec1 = $(window).scrollTop();
        var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
        var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
        if(currentElementOffsetscrollsec1 < 5){
          //console.log('second section reached top of screen');
          $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
          $('.measurement-block-onscroll .measurement-btn').fadeIn();
        }else{
          $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
          $('.measurement-block-onscroll .measurement-btn').fadeOut();
          $('.mb-os-form').hide();
        }
      });
      $('.measurement-block-onscroll .measurement-btn').on('click',function(){
        $('.mb-os-form').fadeToggle(100);
        /* $('body').addClass('noscroll'); */
        if($('.measurements-form-overlay').is(':visible')){
          $('.measurements-form-overlay').hide();
        }else{
          $('.measurements-form-overlay').show();
        }
        if($('body').hasClass('noscroll')){
          $('body').removeClass('noscroll')
        }else{
          $('body').addClass('noscroll')
        }
      });
      /*Measurements form toggle onscroll*/
      /*Measurements button to hide on scroll*/
      const showMeasurementAnim = gsap
        .from(".measurement-block-onscroll", {
          opacity: 0,
          paused: true,
          duration: 0.1,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 999999,
        onUpdate: (self) => {
          self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
        },
      });
      /*Measurements button to hide on scroll*/
      /*Navigation to hide on scroll*/
      const showAnim = gsap
        .from(".top-nav", {
          yPercent: -150,
          paused: true,
          duration: 0.3,
        })
        .progress(1);

        ScrollTrigger.create({
          trigger:".sec-nav-blur",
          start: "top 50%",
          end: 999999,
          onUpdate: (self) => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
          },
          //markers: true,
          // markers:{
          //   startColor: '#f00',
          //   endColor: '#9179CD',
          //   fontSize: '2rem'
          // }
        });
      /*Navigation to hide on scroll*/
      })

      // call api for how goldie works page
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/partner-with-us`);
      if (response && response.status === 200) {
        const data = response.data.data;
        this.partnerWithUs = data;
        document.title = `${this.partnerWithUs.metaTitle || "Fit Goldie"}`;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${this.partnerWithUs.metaDesc || "Fit Goldie"}`);
        } else {
          const newMetaDescription = document.createElement('meta');
          newMetaDescription.setAttribute('name', `${this.partnerWithUs.metaTitle || "Fit Goldie"}`);
          newMetaDescription.setAttribute('content', `${this.partnerWithUs.metaDesc || "Fit Goldie"}`);
          document.head.appendChild(newMetaDescription);
        }

        // console.log("this.partnerWithUs------------");
        // console.log(this.partnerWithUs);
      }
    }
  }
</script>

<style></style>