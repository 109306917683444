  <template>
  <div class="measurements-form-overlay"></div>
  <the-banner
    :bannerTitle="howGoldieWorks.bannerTitle"
  ></the-banner>
  <section-two
    :sectionTwoTitle="howGoldieWorks.sectionTwoTitle"
    :sectionTwoDesc="howGoldieWorks.sectionTwoText"
    :stepOneImg="howGoldieWorks.sectionThreeMeasurementOneImage"
    :stepOneTitle="howGoldieWorks.sectionThreeMeasurementOneTitle"
    :stepOneDesc="howGoldieWorks.sectionThreeMeasurementOneDesc"
    :stepOneLink="howGoldieWorks.sectionThreeMeasurementOneLink"
    :stepTwoImg="howGoldieWorks.sectionThreeMeasurementTwoImage"
    :stepTwoTitle="howGoldieWorks.sectionThreeMeasurementTwoTitle"
    :stepTwoDesc="howGoldieWorks.sectionThreeMeasurementTwoDesc"
    :stepTwoLink="howGoldieWorks.sectionThreeMeasurementTwoLink"
    :stepThreeImg="howGoldieWorks.sectionThreeMeasurementThreeImage"
    :stepThreeTitle="howGoldieWorks.sectionThreeMeasurementThreeTitle"
    :stepThreeDesc="howGoldieWorks.sectionThreeMeasurementThreeDesc"
    :stepThreeLink="howGoldieWorks.sectionThreeMeasurementThreeLink"
    :stepFourImg="howGoldieWorks.sectionThreeMeasurementFourImage"
    :stepFourTitle="howGoldieWorks.sectionThreeMeasurementFourTitle"
    :stepFourDesc="howGoldieWorks.sectionThreeMeasurementFourDesc"
    :stepFourLink="howGoldieWorks.sectionThreeMeasurementFourLink"
  ></section-two>
  <the-testimonial v-if="testimonials.length > 0"
    :alltestimonials="testimonials"
    :testimonialTitle="howGoldieWorks.sectionFourTestimonialTitle"
  ></the-testimonial>
  <the-faq
    :faqTitle="howGoldieWorks.sectionSevenTitle"
    :faqsArrs="faqs"
  ></the-faq>
  <measurement-block></measurement-block>
  <how-to-measure></how-to-measure>
  <the-feedback></the-feedback>
  </template>

  <script>
  import $ from "jquery";
  import axios from "axios";
  import ScrollMagic from "scrollmagic";
  import { gsap, TimelineMax, ScrollTrigger } from "gsap/all";
  //import lottie from 'lottie-web';

  import TheBanner from "../components/layout/how-goldie-works/TheBanner.vue";
  import SectionTwo from "../components/layout/how-goldie-works/SectionTwo.vue";
  import TheTestimonial from "../components/layout/testimonial/TheTestimonial.vue";
  import TheFaq from "../components/layout/faq/TheFaq.vue";
  import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";
  import HowToMeasure from "../components/layout/how-to-measure/HowToMeasure.vue";
  import TheFeedback from "../components/layout/feedback/TheFeedback.vue";

  export default {
    components: {
      TheBanner,
      SectionTwo,
      TheTestimonial,
      TheFaq,
      MeasurementBlock,
      HowToMeasure,
      TheFeedback
    },
    data () {
      return {
        testimonials: [],
        faqs: [],
        howGoldieWorks: {}
      }
    },
    async mounted() {
      gsap.registerPlugin(TimelineMax);
      gsap.registerPlugin(ScrollTrigger);

      $(document).ready(function () {
        /*Testimonials slider*/
        $(".testimonial-slider").slick({
          infinite: true,
          /* adaptiveHeight: true, */
          /* centerMode: true, */
          prevArrow: ".slider-prev",
          nextArrow: ".slider-next",
          cssEase: "ease-in-out",
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });

        // Find the tallest div height
        var tallestHeight = 0;
        $('.testimonial-text').each(function() {
            var currentHeight = $(this).height();
            if (currentHeight > tallestHeight) {
                tallestHeight = currentHeight;
            }
        });

        // Assign the tallest height to all divs in the list
        $('.testimonial-text').css('height', tallestHeight + 'px');
        /*Testimonials slider*/


        /*FAQ Accordion*/
        $('.accordion-button').on('click', function () {
          $(this).children('.arrow-icon').toggleClass('rotate');
        });
        /*FAQ Accordion*/


        /*Measurement button form toggle*/
        $('.measurement-btn').on('click', function () {
          $('.measurement-btn .arrow-icon').toggleClass('rotate');
        })

        // Define onStart function
        function onReverseCompleteFunction() {
          $('.measurement-btn .arrow-icon').removeClass('rotate');
        }
        function onStartFunction() {
          $('.measurement-btn .arrow-icon').addClass('rotate');
        }
        document.querySelector(".measurement-btn").addEventListener("click", animateIt);

        var tl = new TimelineMax();
        tl.to(".search-measurements-block", 0.1, {
          // y: 0,
          display: 'flex'
        })
          .to(".measurement-btn-block", 0.1, {
            marginTop: 0,
            //ease:Power3.easeIn,
            onStart: onStartFunction,// Assign onStart callback function
            onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
          }, "<");
        tl.reverse(-1)
        tl.reversed(true);

        function animateIt() {
          tl.reversed(!tl.reversed());
        }
        /*Measurement button form toggle*/
        /*Measurements form toggle onscroll*/
        $(window).bind('scroll', function() {
          var scrollsec1 = $(window).scrollTop();
          var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
          var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
          if(currentElementOffsetscrollsec1 < 5){
            //console.log('second section reached top of screen');
            $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
            $('.measurement-block-onscroll .measurement-btn').fadeIn();
          }else{
            $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
            $('.measurement-block-onscroll .measurement-btn').fadeOut();
            $('.mb-os-form').hide();
          }
        });
        $('.measurement-block-onscroll .measurement-btn').on('click',function(){
            $('.mb-os-form').fadeToggle(100);
            /* $('body').addClass('noscroll'); */
            if($('.measurements-form-overlay').is(':visible')){
              $('.measurements-form-overlay').hide();
            }else{
              $('.measurements-form-overlay').show();
            }
            if($('body').hasClass('noscroll')){
              $('body').removeClass('noscroll')
            }else{
              $('body').addClass('noscroll')
            }
        });

      /*Measurements form toggle onscroll*/
        const showMeasurementAnim = gsap
          .from(".measurement-block-onscroll", {
            opacity: 0,
            paused: true,
            duration: 0.1,
          })
          .progress(1);

        ScrollTrigger.create({
          start: "top top",
          end: 999999,
          onUpdate: (self) => {
            self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
          },
        });
        /*Navigation to hide on scroll*/
        const showAnim = gsap
          .from(".top-nav", {
            yPercent: -150,
            paused: true,
            duration: 0.3,
          })
          .progress(1);

          ScrollTrigger.create({
            trigger:".sec-nav-blur",
            start: "top 100",
            end: 999999,
            onUpdate: (self) => {
              self.direction === -1 ? showAnim.play() : showAnim.reverse();
            },
            // markers: true,
            // markers:{
            // 	startColor: '#f00',
            // 	endColor: '#9179CD',
            // 	fontSize: '2rem'
            // }
          });
        /*Navigation to hide on scroll*/


      })
      window.onload = function() {
        // Calculate height after content is fully loaded
        // const durationHeight = $('.hiw-steps-block').outerHeight()
        // console.log(durationHeight);
        // Initialize ScrollMagic after Vue.js has finished rendering
        var controller = new ScrollMagic.Controller();
        if ($('.hiw-steps-progress').length > 0) {
          var durationHeight = $('.hiw-steps-block').outerHeight();
          // console.log(durationHeight);
        }
        //console.log(durationHeight);

        // Build scene
        new ScrollMagic.Scene({
          triggerElement: ".hiw-steps-block",
          duration: durationHeight
        })
        .addTo(controller)
        .on("update", function (e) {
          $("#scrollDirection").text(e.target.controller().info("scrollDirection"));
        })
        .on("enter leave", function (e) {
          $("#state").text(e.type == "enter" ? "inside" : "outside");
        })
        .on("start end", function (e) {
          $("#lastHit").text(e.type == "start" ? "top" : "bottom");
        })
        .on("progress", function (e) {
          $("#progress").text(e.progress.toFixed(3) * 100);
          if ((e.progress.toFixed(3) * 100) > 25) {
            $('.hiw-steps-handle').css({
              'top': (e.progress.toFixed(3) * 100) - 25 + '%'
            });
          }
        });
      };
      /* How it works steps animation*/
      $(window).bind('scroll', function() {
        $('.hiw-step-item').each(function(){
          var hiwscrollTop = $(window).scrollTop();
          var hiwelementOffset = $(this).offset().top;
          var hiwcurrentElementOffset = (hiwelementOffset - hiwscrollTop);
          //console.log(hiwcurrentElementOffset);
          if(hiwcurrentElementOffset < 200){
            $('.hiw-item-divider').removeClass('active')
            $(this).children('.hiw-item-content').children('.hiw-item-center').children('.hiw-item-divider').addClass('active');
            $(this).children('.hiw-item-content').children('.hiw-item-content-right').children('.hiw-step-title').children('.hiw-step-no').addClass('active');
            $(this).children('.hiw-item-content').children('.hiw-item-content-left').children('.hiw-step-title').children('.hiw-step-no').addClass('active');
          }else{
            $(this).children('.hiw-item-content').children('.hiw-item-center').children('.hiw-item-divider').removeClass('active');
            $(this).children('.hiw-item-content').children('.hiw-item-content-right').children('.hiw-step-title').children('.hiw-step-no').removeClass('active');
            $(this).children('.hiw-item-content').children('.hiw-item-content-left').children('.hiw-step-title').children('.hiw-step-no').removeClass('active');
          }
        })
      });
      /* How it works steps animation*/
      // call api for FAQ
      const faqs = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/faqs/all`);
      this.faqs = faqs.data.data;
      // console.log(this.faqs);

      // call api for how goldie works page
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/how-it-works`);
      if (response && response.status === 200) {
        const data = response.data.data;
        this.howGoldieWorks = data;
        document.title = `${this.howGoldieWorks.metaTitle || "Fit Goldie"}`;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${this.howGoldieWorks.metaDesc || "Fit Goldie"}`);
        } else {
          const newMetaDescription = document.createElement('meta');
          newMetaDescription.setAttribute('name', `${this.howGoldieWorks.metaTitle || "Fit Goldie"}`);
          newMetaDescription.setAttribute('content', `${this.howGoldieWorks.metaDesc || "Fit Goldie"}`);
          document.head.appendChild(newMetaDescription);
        }

        // call api for testimonials
        const testimonials = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/testimonial/all`);
        const testiData = testimonials.data.data;
        this.testimonials = testiData;

        // call api for FAQ
        const faqs = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/faqs/all`);
        this.faqs = faqs.data.data;
      }

      // call api for testimonials
      const testimonials = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/testimonial/all`);
      const data = testimonials.data.data;
      this.testimonials = data;
    }
  }
  </script>

  <style></style>
