<template>
  <section class="about-sec-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="featured-in-block">
            <h2 class="d-flex flex-wrap w-100">
              <svg width="36" height="65" viewBox="0 0 36 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.62916 62.0586C12.1983 53.8927 18.478 46.1218 24.7204 37.7068C27.6976 33.6932 30.69 28.9287 33.4687 24.7684C27.3351 24.2326 23.9901 24.8341 17.8764 24.2346C17.8744 24.2346 20.339 11.3327 21.7891 2.05859C12.8032 11.2782 5.52691 26.9568 2 35.8205C6.31303 36.0682 10.723 36.2534 15.0433 36.1372C12.0144 44.8085 8.76235 53.4299 5.6285 62.0586H5.62916Z"
                  fill="#F2C840" stroke="#F2C840" stroke-width="4" stroke-miterlimit="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span class="fs-48 fw-600">{{ featuredTitle }}</span>
            </h2>
            <div class="featured-in-list">
              <div class="fi-item" v-for="featured in featuredLinkLogos" :key=featured.logo>
                <a :href="featured.link" target="_blank"><img :src="`${baseUrl}${featured.logo}`" alt="" class="fi-img"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: ["featuredTitle", "featuredLogos", "featuredLinkLogos"],
    data () {
      return {
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    }
  }
</script>

<style></style>
