<template>
  <section class="about-sec-2 sec-nav-blur">
    <img src="../../../assets/images/about-goldie-bg-pattern-left.svg" alt="" class="about-sec-2-bg-left">
    <img src="../../../assets/images/about-goldie-bg-pattern-right.svg" alt="" class="about-sec-2-bg-right">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="about-banner-image-block">
            <img :src="`${baseUrl}${bannerImage}`" alt="" class="about-banner-img">
            <svg width="96" height="192" viewBox="0 0 96 192" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="about-sec-2-star">
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M-6.99382e-07 176L15.4456 175.446L16 160L16.5544 175.446L32 176L16.5544 176.554L16 192L15.4456 176.554L-6.99382e-07 176Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M-6.99382e-07 144L15.4456 143.446L16 128L16.5544 143.446L32 144L16.5544 144.554L16 160L15.4456 144.554L-6.99382e-07 144Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M-6.99382e-07 112L15.4456 111.446L16 96L16.5544 111.446L32 112L16.5544 112.554L16 128L15.4456 112.554L-6.99382e-07 112Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M-6.99382e-07 80L15.4456 79.4456L16 64L16.5544 79.4456L32 80L16.5544 80.5544L16 96L15.4456 80.5544L-6.99382e-07 80Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M-6.99382e-07 48L15.4456 47.4456L16 32L16.5544 47.4456L32 48L16.5544 48.5544L16 64L15.4456 48.5544L-6.99382e-07 48Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M-6.99382e-07 16L15.4456 15.4456L16 1.20797e-06L16.5544 15.4456L32 16L16.5544 16.5544L16 32L15.4456 16.5544L-6.99382e-07 16Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M32 176L47.4456 175.446L48 160L48.5544 175.446L64 176L48.5544 176.554L48 192L47.4456 176.554L32 176Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M32 144L47.4456 143.446L48 128L48.5544 143.446L64 144L48.5544 144.554L48 160L47.4456 144.554L32 144Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M32 112L47.4456 111.446L48 96L48.5544 111.446L64 112L48.5544 112.554L48 128L47.4456 112.554L32 112Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M32 80L47.4456 79.4456L48 64L48.5544 79.4456L64 80L48.5544 80.5544L48 96L47.4456 80.5544L32 80Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M32 48L47.4456 47.4456L48 32L48.5544 47.4456L64 48L48.5544 48.5544L48 64L47.4456 48.5544L32 48Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M32 16L47.4456 15.4456L48 1.20797e-06L48.5544 15.4456L64 16L48.5544 16.5544L48 32L47.4456 16.5544L32 16Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M64 176L79.4456 175.446L80 160L80.5544 175.446L96 176L80.5544 176.554L80 192L79.4456 176.554L64 176Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M64 144L79.4456 143.446L80 128L80.5544 143.446L96 144L80.5544 144.554L80 160L79.4456 144.554L64 144Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M64 112L79.4456 111.446L80 96L80.5544 111.446L96 112L80.5544 112.554L80 128L79.4456 112.554L64 112Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M64 80L79.4456 79.4456L80 64L80.5544 79.4456L96 80L80.5544 80.5544L80 96L79.4456 80.5544L64 80Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M64 48L79.4456 47.4456L80 32L80.5544 47.4456L96 48L80.5544 48.5544L80 64L79.4456 48.5544L64 48Z"
                  fill="#0F2C66" />
              </g>
              <g style="mix-blend-mode:multiply" opacity="0.31">
                <path
                  d="M64 16L79.4456 15.4456L80 1.20797e-06L80.5544 15.4456L96 16L80.5544 16.5544L80 32L79.4456 16.5544L64 16Z"
                  fill="#0F2C66" />
              </g>
            </svg>
          </div>
          <div class="about-goldie-content-block d-flex flex-wrap w-100">
            <div class="agcb-left">
              <h2 class="fs-48 fw-600">{{ aboutTitle }}</h2>
            </div>
            <div class="agcb-right">
              <div class="agcb-left-text fs-16" v-html="aboutDesc">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="about-finder-title-block">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="about-finder-title-content">
              <h2 class="fs-40 fw-400">{{ journey }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-finder-graphic-block" id="about-finder-graphic-block">
      <div id="about-finder-graphic-img"></div>
      <!-- <img :src="`${baseUrl}${journeyImg}`" alt="" class="about-finder-graphic-img">      -->
    </div>
    <div class="about-finder-cta" v-if="btnSwitch">
      <a href="/products" class="fg-btn-primary fg-btn-primary-blue">
        <span>Get Started</span>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
            stroke="white" stroke-width="2"></path>
        </svg>
      </a>
    </div>
    <div class="about-finder-cta" v-else>
      <a href="javascript:void(0);" class="fg-btn-primary fg-btn-primary-blue signup-login-link">
        <span>Get Started</span>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
            stroke="white" stroke-width="2"></path>
        </svg>
      </a>
    </div>
  </section>
</template>

<script>
  import $ from "jquery";
  //import { gsap, TimelineMax, ScrollTrigger } from "gsap/all";
  import ScrollMagic from "scrollmagic";
  import lottie from 'lottie-web';

  export default {
    props: ["bannerImage", "aboutTitle", "aboutDesc", "journey", "journeyImg"],
    data () {
      return {
        btnSwitch: false,
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    mounted() {
      /*Newsletter bags animation scrollmagic*/
        // Load Lottie animation
        var about_animation = lottie.loadAnimation({
            container: document.getElementById('about-finder-graphic-img'), // The DOM element to render the animation in
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: 'animations/about.json' // The path to the animation JSON file
        });

        // Initialize ScrollMagic Controller
        var controller_about_animation = new ScrollMagic.Controller();

        // Create a ScrollMagic Scene
        new ScrollMagic.Scene({
            triggerElement: '#about-finder-graphic-block', // The element that triggers the animation
            duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
            triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
        })
        .addTo(controller_about_animation)
        .on('enter', function () {
            // Add a delay of 5 seconds before starting the animation
            setTimeout(function () {
                //about_animation.play(); //Play animation only once
                about_animation.goToAndStop(0, true);
                about_animation.play();
            }, 0); // 5000 milliseconds = 5 seconds
        })
        .on('leave', function () {
            // Hold the end state of the animation when leaving the scene
            about_animation.goToAndStop(about_animation.totalFrames - 1, true);
        });
      /*Newsletter bags animation scrollmagic*/
      //gsap.registerPlugin(TimelineMax);
      //gsap.registerPlugin(ScrollTrigger);
      $(document).ready(function () {
        // /*Graphic Animations*/
        // LottieScrollTrigger({
        //   target: "#about-finder-graphic-img",
        //   trigger: "#about-finder-graphic-block",
        //   start: 'top 30%',
        //   end: 'top 30%',
        //   path: 'animations/about.json',
        //   scrub: 3,
        //   pin: false
        // });
        // //helper function
        // function LottieScrollTrigger(vars) {
        //   let playhead = {frame: 0},
        //     target = gsap.utils.toArray(vars.target)[0],
        //     speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
        //     st = {trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1},
        //     animation = lottie.loadAnimation({
        //       container: target,
        //       renderer: vars.renderer || "svg",
        //       loop: false,
        //       autoplay: false,
        //       path: vars.path
        //     });
        //   for (let p in vars) { // let users override the ScrollTrigger defaults
        //     st[p] = vars[p];
        //   }
        //   animation.addEventListener("DOMLoaded", function() {
        //     gsap.to(playhead, {
        //       duration: vars.duration || 0.5,
        //       delay: vars.delay || 0,
        //       frame: (animation.totalFrames - 1) * (vars.progress || 1),
        //       ease: vars.ease || "none",
        //       onUpdate: () => animation.goToAndStop(playhead.frame, true),
        //       scrollTrigger: st
        //     });
        //     // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
        //     ScrollTrigger.sort();
        //     ScrollTrigger.refresh();
        //   });
        //   return animation;
        // }
        /*Graphic Animations*/
      });

      let isLoggedIn = this.$store.getters.isAuth;
      if (isLoggedIn) {
        this.btnSwitch = true;
      } else {
        this.btnSwitch = false;
      }
    }
  }
</script>

<style></style>
