<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <the-header v-if="!$route.meta.hideHeader"></the-header>
  <router-view></router-view>
  <the-footer v-if="!$route.meta.hideFooter"></the-footer>
  <the-auth></the-auth>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { gsap, ScrollTrigger } from "gsap/all";

import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import TheAuth from "./components/layout/auth/TheAuth.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
    TheAuth
  },
  data() {
    return {
      showFeedback: false
    }
  },
  async created() {
    const script = document.createElement('script');
    script.src = '/js/script.js'; // taking from public folder
    script.async = true;
    document.head.appendChild(script);
  },
  methods: {
    checkForReturningUser: function () {
      const visitedBefore = localStorage.getItem('visitedBefore');
      if (visitedBefore) {
        this.showFeedback = true;
      } else {
        localStorage.setItem('visitedBefore', true);
      }
    }
  },
  async mounted() {
    gsap.registerPlugin(ScrollTrigger);
    $(".hamburger").click(function () {
      $(this).toggleClass("is-active");
    });

    /*Navigation background blur on scroll*/
    $(window).bind('scroll', function() {
      var scrollsec1 = $(window).scrollTop();
      if ($('.sec-nav-blur').length) {
        var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
        var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
        if(currentElementOffsetscrollsec1 < 5){
          $('.fg-navigation .top-nav .container').addClass('nav-bg-blur')
        }else{
          $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur')
        }
      }
    });
    /*Navigation background blur on scroll*/

    /*Form label animation*/
    $('.home-banner-form input,.home-banner-form textarea').on('focusin', function() {
      $(this).parent().find('label').addClass('active');
    });

    $('.home-banner-form input,.home-banner-form textarea').on('focusout', function() {
      if (!this.value) {
          $(this).parent().find('label').removeClass('active');
      }
    });
    /*Form label animation*/

    // API calling...
    let urlParams = new URLSearchParams(window.location.search);
    let recog = urlParams.get('recog');

    let getToken = localStorage.getItem("token");
    this.$store.dispatch('autoLogin');
    localStorage.getItem("visitedBefore");
    if (getToken) {
      const checkSent = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/check/sent`, {}, {
        headers: {
          Authorization: "Bearer " + getToken
        }
      });
      if (checkSent && checkSent.status === 200 && checkSent.data.code == 200) {
        const finResponse = checkSent.data.data;
        if (finResponse.feedbackThere) {
          if (finResponse.sentFeedback && finResponse.sentFeedback.showFeedback == 1) {
            // check expiry
            const now = new Date();
            const expiryTime = new Date(finResponse.sentFeedback.expiry).getTime();
            if (now.getTime() > expiryTime) {
              setTimeout(async () => {
                $('.popup-feedback').css({'display':'flex'});
                $('body').addClass('noscroll');
                localStorage.removeItem("visitedBefore");
                await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/send?pending=0`, {}, {
                  headers: {
                    Authorization: "Bearer " + getToken
                  }
                });
              }, 5000);
            }
          }
        }
      }
    }
    if (recog) {
      setTimeout(async () => {
        $('.popup-feedback').css({ 'display': 'flex' });
        $('body').addClass('noscroll');
        localStorage.removeItem("visitedBefore");
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/feedback/send/from/mail?pending=0&recog=${recog}`, {}, {});
      }, 1500);
    }
  }
};
</script>

<style>
</style>
