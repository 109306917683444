export default {
  getToken(state) {
    return state.token;
  },
  getUserId(state) {
    return state._id;
  },
  getName(state) {
    const getName = localStorage.getItem("name");
    const stateName = state.name;
    if (stateName || getName) {
      return stateName || getName
    }
  },
  getEmail() {
    const getEmail = localStorage.getItem("email");
    return getEmail;
  },
  isAuth(state) {
    return !!state.token
  },
  name() {
    const getName = localStorage.getItem("name");
    return getName;
  }
};
