<template>
  <section class="measurement-sec-top search-measurement-top">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="submitMeasurementForm">
            <div class="home-banner-form search-measurements-block" :class="{ 'home-banner-email': showEmailInput }">
              <div class="text-box text-box-small">
                <label :class="{ 'active': waist }" for="waistBlock">Waist<sup>*</sup></label>
                <input id="waistBlock" type="text" autocomplete="off" v-model="waist" />
                <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
              </div>
              <div class="text-box text-box-small">
                <label :class="{ 'active': hip }" for="hipsMeasureBlock">Hips<sup>*</sup></label>
                <input id="hipsMeasureBlock" type="text" autocomplete="off" v-model="hip" />
                <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
              </div>
              <div class="text-box text-box-small">
                <label :class="{ 'active': thigh }" for="thighBlock">Thigh<sup>*</sup></label>
                <input id="thighBlock" type="text" autocomplete="off" v-model="thigh" />
                <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
              </div>
              <div class="text-box text-box-small">
                <label :class="{ 'active': inseam }" for="inseamBlock">Inseam<sup>*</sup></label>
                <input id="inseamBlock" type="text" autocomplete="off" v-model="inseam" />
                <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message
                  }}</span>
              </div>
              <div class="text-box text-box-large" v-if="showEmailInput">
                <label :class="{ 'active': email }" for="emailBanner">Email<sup>*</sup></label>
                <input id="emailBanner" type="text" autocomplete="off" v-model="email" />
                <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
              </div>
              <div class="home-form-cta">
                <button type="submit" class="fg-btn-primary w-100">
                  <span>Update</span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                      stroke="#0F2C66" stroke-width="2" />
                  </svg>
                </button>
              </div>
            </div>
          </form>
          <div class="measurement-btn-block">
            <a href="javascript:void(0);" class="measurement-btn">
              <span v-if="isLoggedIn">Measurements </span>
              <span v-else>Let's find your perfect fit! </span>
              <svg class="arrow-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9.22363L12 15.2236L18 9.22363" stroke="#F2C840" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";
  import { gsap, TimelineMax } from "gsap/all";

  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers, between, email } from '@vuelidate/validators';

  export default {
    data () {
      return {
        v$: useVuelidate(),
        waist: "",
        hip: "",
        thigh: "",
        inseam: "",
        email: "",
        showEmailInput: true
      }
    },
    validations() {
      return {
        email: {
          required: helpers.withMessage('Email is required.', required),
          email: helpers.withMessage('Invalid email address.', email),
        },
        waist: this.waistValidation,
        hip: this.hipValidation,
        thigh: this.thighValidation,
        inseam: this.inseamValidation
      }
    },
    computed: {
      loggedInEmail: function () {
        return this.$store.getters.getEmail;
      },
      isLoggedIn: function () {
        return this.$store.getters.isAuth;
      },
      measurementType: function () {
        return localStorage.getItem("measurementType");
      },
      waistValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Waist is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Waist is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      hipValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Hip is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Hip is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      thighValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Thigh is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Thigh is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      },
      inseamValidation: function () {
        if (this.measurementType === "inches") { // inches
          return {
            required: helpers.withMessage('Inseam is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 99))
          };
        } else { // cms
          return {
            required: helpers.withMessage('Inseam is required.', required),
            between: helpers.withMessage('Invalid value.', between(11, 161))
          };
        }
      }
    },
    methods: {
      submitMeasurementForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        if (!validateForm) return;
        // this.$store.getters['pages/measurementFromUser'];
        this.$store.dispatch('products/measurementFromUser', {
          waist: this.waist,
          hip: this.hip,
          thigh: this.thigh,
          inseam: this.inseam,
          email: this.email,
          measurementType: this.measurementType ? this.measurementType : "inches"
        });
        if (this.isLoggedIn == false) {
          // call api for measurement
          await axios.post(`${process.env.VUE_APP_API_BASE_URL}/measurement/save`, {
            waist: this.waist,
            hip: this.hip,
            thigh: this.thigh,
            inseam: this.inseam,
            email: this.email
          });
        }
        this.$router
          .push({ path: "/products" })
          .then(() => {
            this.$router.go(0);
          })
        return;
      }
    },
    mounted() {
      gsap.registerPlugin(TimelineMax);

      let measurement = this.$store.getters['products/getMeasurementFromLocal'];
      this.waist = measurement.waist;
      this.hip = measurement.hip;
      this.thigh = measurement.thigh;
      this.inseam = measurement.inseam;
      this.email = measurement.email;

      if (this.isLoggedIn) {
        this.showEmailInput = false;
      }

      $('.measurement-btn').on('click', function () {
        $('.measurement-btn .arrow-icon').toggleClass('rotate');
      })

      // Define onStart function
      function onReverseCompleteFunction() {
        //$('.measurement-btn-block').removeClass('open');
        $('.measurement-btn .arrow-icon').removeClass('rotate');
        //$('.product-modal-content').removeClass('measurement-block-open');
      }
      function onStartFunction() {
        //$('.measurement-btn-block').addClass('open');
        $('.measurement-btn .arrow-icon').addClass('rotate');
        //$('.product-modal-content').addClass('measurement-block-open');
      }
      document.querySelector(".measurement-btn").addEventListener("click", animateIt);

      let tl = new TimelineMax();
      tl.to(".search-measurements-block", 0.8, {
        y: 0,
        //ease:Power3.easeIn,
      })
        .to(".measurement-btn-block", 1, {
          marginTop: 0,
          //ease:Power3.easeIn,
          onStart: onStartFunction,// Assign onStart callback function
          onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
        }, "<");
        tl.reverse(-1)
        tl.reversed(true);

      function animateIt() {
        tl.reversed(!tl.reversed());
      }
    }
  }
</script>

<style></style>
