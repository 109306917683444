  <template>
  <!-- <pre>{{ homeData }}</pre> -->
  <div class="measurements-form-overlay"></div>
  <home-banner
    :bannerTitle="homeData.bannerTitle"
    :bannerSubTitle="homeData.bannerSubTitle"
    :bannerImage="homeData.bannerImage"
    :bannerMeasure="homeData.bannerMeasureText"
  ></home-banner>
  <top-picks v-if="isLoggedIn && topPicksProducts && topPicksProducts.length"
    :topPickTitle="homeData.topPicksText"
    :topPickImg="homeData.topPicksBanner"
    :topPicksProductsArr="topPicksProducts"
  ></top-picks>
  <the-favourites v-if="isLoggedIn && products.length > 0" :favProducts="products"></the-favourites>
  <the-counts
    :illustrationOne="homeData.sectionTwoImageOne"
    :illustrationOneTitle="homeData.sectionTwoTextOne"
    :illustrationOneNumbers="homeData.sectionTwoNumberOne"
    :illustrationTwo="homeData.sectionTwoImageTwo"
    :illustrationTwoTitle="homeData.sectionTwoTextTwo"
    :illustrationTwoNumbers="homeData.sectionTwoNumberTwo"
    :illustrationThree="homeData.sectionTwoImageThree"
    :illustrationThreeTitle="homeData.sectionTwoTextThree"
    :illustrationThreeNumbers="homeData.sectionTwoNumberThree"
    :illustrationFour="homeData.sectionTwoImageFour"
    :illustrationFourTitle="homeData.sectionTwoTextFour"
    :illustrationFourNumbers="homeData.sectionTwoNumberFour"
    :floatingText="homeData.floatingText"
  ></the-counts>
  <how-goldie-works
    :sectionThreeTitle="homeData.sectionThreeTitle"
    :sectionThreeDescrition="homeData.sectionThreeDesc"
    :sectionThreeStepOneTitle="homeData.sectionThreeMeasurementOneTitle"
    :sectionThreeStepOneDesc="homeData.sectionThreeMeasurementOneDesc"
    :sectionThreeStepOneLink="homeData.sectionThreeMeasurementOneLink"
    :sectionThreeStepOneImg="homeData.sectionThreeStepOneImg"
    :sectionThreeStepTwoTitle="homeData.sectionThreeMeasurementTwoTitle"
    :sectionThreeStepTwoDescription="homeData.sectionThreeMeasurementTwoDesc"
    :sectionThreeStepTwoLink="homeData.sectionThreeMeasurementTwoLink"
    :sectionThreeStepTwoImg="homeData.sectionThreeStepTwoImg"
    :sectionThreeStepThreeTitle="homeData.sectionThreeMeasurementThreeTitle"
    :sectionThreeStepThreeDesc="homeData.sectionThreeMeasurementThreeDesc"
    :sectionThreeStepThreeLink="homeData.sectionThreeMeasurementThreeLink"
    :sectionThreeStepThreeImg="homeData.sectionThreeStepThreeImg"
    :sectionThreeStepFourTitle="homeData.sectionThreeMeasurementFourTitle"
    :sectionThreeStepFourDesc="homeData.sectionThreeMeasurementFourDesc"
    :sectionThreeStepFourLink="homeData.sectionThreeMeasurementFourLink"
    :sectionThreeStepFourImg="homeData.sectionThreeStepFourImg"
  ></how-goldie-works>
  <the-testimonial v-if="testimonials.length > 0" :alltestimonials="testimonials"
    :testimonialTitle="homeData.sectionFourTestimonialTitle"
  ></the-testimonial>
  <notified-style
    :notifiedTitle="homeData.sectionFiveText"
  ></notified-style>
  <favourite-jeans
    :favTitle="homeData.sectionSixTitle"
    :favDesc="homeData.sectionSixDesc"
    :favImages="homeData.sectionSixJeansImages"
    :favLogos="homeData.sectionSixLogos"
    :notAffilated="homeData.sectionSixText"
  ></favourite-jeans>
  <product-detail-popup
    :brandName="singleProduct.brandName"
    :productName="singleProduct.productName"
    :productImages="singleProduct.productImages"
    :fullSize="singleProduct.fullSize"
    :productUrl="singleProduct.productUrl"
    :productDesc="singleProduct.productDesc"
    :stretch="singleProduct.stretch"
    :waistNotes="singleProduct.waistNotes"
    :hipNotes="singleProduct.hipNotes"
    :thighNotes="singleProduct.thighNotes"
    :inseamNotes="singleProduct.inseamNotes"
    :riseInProduct="singleProduct.rise"
    :isBookmark="singleProduct.isBookmark"
    :id="singleProduct.id"
    :isBookmarkBtn="singleProduct.isBookmarkBtn"
  ></product-detail-popup>
  <how-to-measure></how-to-measure>
  </template>

  <script>
  import axios from "axios";
  import $ from "jquery";
  import { gsap, ScrollTrigger } from "gsap/all";
  import _ from 'lodash';
  //import lottie from 'lottie-web';


  import HomeBanner from "../components/layout/home/Homebanner.vue";
  import TopPicks from "../components/layout/home/TopPicks.vue";
  import TheFavourites from "../components/layout/home/TheFavourites.vue";
  import TheCounts from "../components/layout/home/TheCounts.vue";
  import HowGoldieWorks from "../components/layout/home/HowGoldieWorks.vue";
  import TheTestimonial from "../components/layout/testimonial/TheTestimonial.vue";
  import NotifiedStyle from "../components/layout/subscription/NotifiedStyle.vue";
  import FavouriteJeans from "../components/layout/home/FavouriteJeans.vue";
  import ProductDetailPopup from "../components/layout/products/ProductDetailPopup.vue";
  import HowToMeasure from "../components/layout/how-to-measure/HowToMeasure.vue";

  export default {
  components: {
    HomeBanner,
    TopPicks,
    TheFavourites,
    TheCounts,
    HowGoldieWorks,
    TheTestimonial,
    NotifiedStyle,
    FavouriteJeans,
    ProductDetailPopup,
    HowToMeasure,
  },
  data() {
    return {
      homeData: {},
      isLoggedIn: false,
      clickEventAssigned: false,
      products: [],
      singleProduct: {},
      testimonials: [],
      topPicksProducts: []
    };
  },
  async mounted() {
    gsap.registerPlugin(ScrollTrigger);

    $('body').removeClass('noscroll');
    //
    $(document).on('click', '.share-feedback-link' ,function(){
      $('.popup-feedback').css({'display':'flex'});
      $('body').addClass('noscroll');
    })

    $(window).bind('scroll', function () {
      var scrollTopForm = $(window).scrollTop();
      var elementOffsetForm = $('.sec-nav-blur').offset().top;
      var currentElementOffsetForm = (elementOffsetForm - scrollTopForm);
      //console.log(currentElementOffsetForm);
      if (currentElementOffsetForm < -250) {
        //$('.home-banner-form-block-content').addClass('scrolled');
        $('.home-banner-form-block-content.scrolled .home-banner-form-block-title').css({'display':'flex'})
        //$('.home-banner-form-block-content.scrolled .home-banner-form-toggle-wrapper').hide()

      } else {
        //$('.home-banner-form-block-content').removeClass('scrolled');
        //$('.home-banner-form-toggle-wrapper').show();
        $('.home-banner-form-block-content.scrolled .home-banner-form-block-title').hide()
        //$('.home-banner-form-block-content.scrolled .home-banner-form-toggle-wrapper').hide()
      }
    });
    // $('.popup-signup-close').on('click',function(){
    //   $('.popup-signup-login').hide();
    //   $('.popup-measurement').hide();
    //   $('.popup-submit-retailer').hide();
    //   $('.popup-feedback').hide();
    //   $('body').removeClass('noscroll');
    // })
    // $(document).on('keyup', function(e) {
    //   if (e.key == "Escape"){
    //     $('.popup-signup-login').hide();
    //     $('.popup-measurement').hide();
    //     $('.popup-submit-retailer').hide();
    //     $('.popup-feedback').hide();
    //     $('body').removeClass('noscroll');
    //   }
    // });
    $(document).ready(function () {
      $('.how-to-measure').on('click',function(){
        $('.popup-measurement').fadeIn();
        $('body').addClass('noscroll');
      });
    })
    /*Product wishlist onclick animation*/
    $('.gpb-wishlist').on('click',function(){
      $(this).toggleClass('active-wishlist');
    })

    /*Lets find your fit button to hide on scroll*/
    const showMeasurementAnim = gsap
      .from(".home-banner-form-block-title", {
        opacity: 0,
        paused: true,
        duration: 0.1,
      })
      .progress(1);

    ScrollTrigger.create({
      trigger:'.sec-nav-blur',
      start: "top 200",
      end: 999999,
      onUpdate: (self) => {
        self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
      },
    });
    /*Lets find your fit  button to hide on scroll*/
    /*Navigation to hide on scroll*/
    const showAnim = gsap
      .from(".top-nav", {
        yPercent: -150,
        paused: true,
        duration: 0.3,
      })
      .progress(1);

      ScrollTrigger.create({
        trigger:".sec-nav-blur",
        start: "top 50%",
        end: 999999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
        //markers: true,
        // markers:{
        //   startColor: '#f00',
        //   endColor: '#9179CD',
        //   fontSize: '2rem'
        // }
      });
    /*Navigation to hide on scroll*/
    /*Home banner form scroll*/
    // $(window).bind('scroll', function() {
    //   var scrollTopForm = $(window).scrollTop();
    //   var elementOffsetForm = $('.sec-nav-blur').offset().top;
    //   var currentElementOffsetForm = (elementOffsetForm - scrollTopForm);
    //   //console.log(currentElementOffsetForm);
    //   if(currentElementOffsetForm < 5 ){
    //   $('.home-banner-form-block-content').addClass('scrolled');
    //   $('.home-banner-form-block-content.scrolled .home-banner-form-toggle-wrapper').hide()
    //   }else{
    //   $('.home-banner-form-block-content').removeClass('scrolled');
    //   $('.home-banner-form-toggle-wrapper').show();
    //   }
    // });
    // if ($(window).width() < 767) {
    //   $(window).bind('scroll', function() {
    //   var scrollTopForm = $(window).scrollTop();
    //   var elementOffsetForm = $('.sec-nav-blur').offset().top;
    //   var currentElementOffsetForm = (elementOffsetForm - scrollTopForm);
    //   //console.log(currentElementOffsetForm);
    //   if(currentElementOffsetForm < 5 ){
    //   $('.home-banner-form-block-content').addClass('scrolled');
    //   $('.home-banner-form-block-content.scrolled .home-banner-form-toggle-wrapper').hide();
    //   $('.home-sec-1').css({'padding':'10rem 0 50.5rem 0'})
    //   }else{
    //   $('.home-banner-form-block-content').removeClass('scrolled');
    //   $('.home-banner-form-toggle-wrapper').show();
    //   $('.home-sec-1').css({'padding':'10rem 0'})
    //   }
    // });
    // }
    /*Home banner form scroll*/
    $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function(){
      $('.product-modal-content').addClass('open');
      $('.fg-navigation').addClass('reduceindex');
      //$('.measurements-form-overlay').show();
      $('body').addClass('noscroll');
    });
    /*Product details block hover*/
    // $(document).on("mouseenter", ".goldie-product-block", function() {
    //   $(this).children('.gpb-content').children('.gpb-content-link').children('.gpb-hover-block-wrapper').fadeIn(10);
    // });
    // $(document).on("mouseleave", ".goldie-product-block", function() {
    //   $(this).children('.gpb-content').children('.gpb-content-link').children('.gpb-hover-block-wrapper').fadeOut(10);
    // });
    /*Product details block hover*/
    // Event Emit
    this.emitter.on("product-detail-data", (data) => {
      // this.singleProduct = data;
      this.singleProduct = {
        productImages: data.product.productImages || [],
        brandName: data.product.brand.name,
        productName: data.product.productName,
        fullSize: data.product.fullSize,
        productUrl: data.product.productUrl,
        productDesc: data.product.productDesc,
        rise: data.product.rise,
        stretch: data.product.stretch,
        waistNotes: data.fitNotes.waistFitNotes,
        hipNotes: data.fitNotes.hipFitNotes,
        thighNotes: data.fitNotes.thighFitNotes,
        inseamNotes: data.fitNotes.inseamFitNotes,
        isBookmark: data.product.isBookmark,
        isBookmarkBtn: data.product.isBookmark ? `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${data.product.productUrl})">
                <span>Shop Now</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                    stroke="white" stroke-width="2" />
                </svg>
              </button>
              <a href="javascript:void(0);" id="${data.product._id}-heart-pdc" class="pdc-wishlist heart-pdc active-wishlist-pdc" @click="bookmarkFav(${data.product._id})">` : `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${data.product.productUrl})">
                <span>Shop Now</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                    stroke="white" stroke-width="2" />
                </svg>
              </button><a href="javascript:void(0);" class="pdc-wishlist heart-pdc" id="${data.product._id}-heart-pdc" @click="bookmarkFav(${data.product._id})">`,
        id: data.product._id
      };
      // console.log("this.singleProduct---------------");
      // console.log(this.singleProduct);
      // console.log(data);
    });

    this.isLoggedIn = this.$store.getters.isAuth;
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/home`);

    if (response && response.status === 200) {
      const data = response.data.data;
      this.homeData = data;
      document.title = `${this.homeData.metaTitle || "Fit Goldie"}`;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', `${this.homeData.metaDesc || "Fit Goldie"}`);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', `${this.homeData.metaTitle || "Fit Goldie"}`);
        newMetaDescription.setAttribute('content', `${this.homeData.metaDesc || "Fit Goldie"}`);
        document.head.appendChild(newMetaDescription);
      }
    }

    // console.log("HOME DATA");
    // console.log(this.homeData);

    // call api for testimonials
    const testimonials = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/testimonial/all`);
    const data = testimonials.data.data;
    this.testimonials = data;
    // console.log(this.testimonials);

    // call api for top picks
    let measurement = this.$store.getters['products/getMeasurementFromLocal'];
    if (this.isLoggedIn) {
      let getToken = this.$store.getters.getToken;
      let headers = {};
      if (getToken) {
        headers.Authorization = "Bearer " + getToken;
      }
      const topPicks = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/top-picks`, {
        waistInput: parseFloat(measurement.waist),
        hipInput: parseFloat(measurement.hip),
        thighInput: parseFloat(measurement.thigh),
        inseamInput: parseFloat(measurement.inseam)
      }, {
        headers
      });

      if (topPicks && topPicks.status == 200 && topPicks.data.code == 200) {
        // console.log("topPicks------");
        // console.log(topPicks);
        this.topPicksProducts = topPicks.data.data.products;
      } else {
        console.log("Top picks products not found");
      }

      // call fav api for favourites
      const favResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/product/get/bookmarked`, {
        headers: {
          Authorization: "Bearer " + getToken
        }
      });

      let data = favResponse.data.data;
      // _.filter()
      if (data && data.products && data.products.length) {
        const isTopPick = (item) => _.some(topPicks.data.data.products, top => _.includes(item._id, top._id));
        const updatedData = _.map(data.products, item => ({
          ...item,
          topPicks: isTopPick(item) ? 1 : 0
        }));
        // console.log("updatedData");
        // console.log(updatedData);
        this.products = updatedData;
      } else {
        data = [];
      }

      // this.products = data.products;
      // console.log("HEHEHEHE");
      // console.log(this.products);
      // console.log("topPicks------");
      // console.log(this.topPicksProducts);
    }
  },
  created() {
    if (!document.querySelector('script[src="/js/homeScreen/homeScript.js"]')) {
      // console.log(this.$route.path);
      const scriptHome = document.createElement('script');
      scriptHome.src = '/js/homeScreen/homeScript.js'; // taking from public folder
      scriptHome.async = true;
      document.head.appendChild(scriptHome);
    }
  },
  methods: {
    toggleForm() {
      $('.home-banner-form-toggle-wrapper').fadeToggle(300);
      $(this).children('.form-toggle-arrow').toggleClass('rotate');
      $('.measurements-form-overlay').fadeToggle(1);
      $('body').toggleClass('noscroll');
    }
  }
  }
  </script>

