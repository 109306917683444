<template>
  <footer class="fg-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer-top-block d-flex flex-wrap w-100">
            <div class="quick-links-block">
              <a href="javascript:void(0);">
                <img :src="`${baseUrl}${footerLogo}`" alt="Fit Goldie" class="logo-footer">
              </a>
              <ul class="quick-links-list">
                <li class="quick-link-item"><a href="/about">About Us</a></li>
                <li class="quick-link-item"><a href="/how-goldie-works">How Goldie Works</a></li>
                <li class="quick-link-item"><a href="/partner-with-us">Partner with Us</a></li>
                <li class="quick-link-item"><a href="javascript:void(0);" class="share-feedback-link">Share Feedback</a>
                </li>
              </ul>
            </div>
            <div class="retailer-form-block">
              <h3 class="fs-24 fw-500 text-white">Retailer Submission</h3>
              <form class="retailer-form-footer" @submit.prevent="submitRetailerForm">
                <div class="home-banner-form d-flex flex-wrap w-100">
                  <div class="text-box">
                    <label for="retailer_name">Retailer Name</label>
                    <input id="retailer_name" type="text" v-model="retailerName" />
                    <span v-for="error in v$.retailerName.$errors" :key="error.uid" class="label-error">{{
                      error.$message }}</span>
                  </div>
                  <div class="text-box">
                    <label for="jeans_style_name">Jeans Style Name</label>
                    <input id="jeans_style_name" type="text" v-model="styleName" />
                  </div>
                  <div class="text-box">
                    <label for="jeans_product_link">Jeans Product Link</label>
                    <input id="jeans_product_link" type="text" v-model="productLink" />
                  </div>
                  <div class="text-box footer-form-cta">
                    <button type="submit" class="fg-btn-primary w-100">
                      <span>Submit a Retailer</span>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                          stroke="#0F2C66" stroke-width="2" />
                      </svg>
                    </button>
                  </div>

                </div>
              </form>
            </div>
          </div>
          <div class="footer-bottom-block d-flex flex-wrap w-100">
            <div class="footer-social-block">
              <a :href="socialIconOneLink" target="_blank" class="footer-social-link">
                <img :src="`${baseUrl}${socialIconOne}`" alt="Fit Goldie">
              </a>
              <a :href="socialIconTwoLink" target="_blank" class="footer-social-link">
                <img :src="`${baseUrl}${socialIconTwo}`" alt="Fit Goldie">
              </a>
              <a :href="socialIconThreeLink" target="_blank" class="footer-social-link">
                <img :src="`${baseUrl}${socialIconThree}`" alt="Fit Goldie">
              </a>
              <a :href="tiktokLink" target="_blank" class="footer-social-link">
                <img :src="`${baseUrl}${tiktokIcon}`" alt="Fit Goldie">
              </a>
              <a :href="`mailto:${mailLink}`" class="footer-social-link">
                <img :src="`${baseUrl}${mailIcon}`" alt="Fit Goldie">
              </a>
            </div>
            <div class="footer-copyright-block text-white fs-16">
              <a :href="textFooterLink" target="_blank" class="legal-link text-capitalize">{{ textFooter }}</a>
              <!-- <a href="javascript:void(0);" class="legal-link">Privacy policy</a> -->
              <span>{{ copyright }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <the-feedback></the-feedback>
</template>

<script>
  import axios from "axios";
  import $ from "jquery";

  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';

  import TheFeedback from "./feedback/TheFeedback.vue";

  export default {
    components: {
      TheFeedback
    },
    data() {
      return {
        v$: useVuelidate(),
        retailerName: "",
        styleName: "",
        productLink: "",
        footerLogo: "",
        socialIconOne: "",
        socialIconTwo: "",
        socialIconThree: "",
        socialIconOneLink: "",
        socialIconTwoLink: "",
        socialIconThreeLink: "",
        mailIcon: "",
        mailLink: "",
        tiktokIcon: "",
        tiktokLink: "",
        textFooter: "",
        textFooterLink: "",
        copyright: "",
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    validations () {
      return {
        retailerName: {
          required: helpers.withMessage('Retailer name is required.', required)
        }
      }
    },
    methods: {
      submitRetailerForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        console.log(validateForm)
        if (!this.retailerName) {
          return
        }
        let getToken = this.$store.getters.getToken;
        let headers = {};
        if (getToken) {
          headers.Authorization = "Bearer " + getToken;
        }
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/retailer/create`, {
          retailerName: this.retailerName,
          knownFor: this.styleName,
          skuLink: this.productLink
        }, {
          headers
        });
        this.v$.$reset();
        this.retailerName = "";
        this.styleName = "";
        this.productLink = "";
        return this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Retailer submitted!",
          showConfirmButton: false,
          timer: 1900,
        });
      }
    },
    async mounted() {
      // Jquery stuff
      $(document).on('click', '.share-feedback-link' ,function(){
        $('.popup-feedback').css({'display':'flex'});
        $('body').addClass('noscroll');
      });

      // Call store for footer logo
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/common/settings?type=footer`);

      if (response && response.status === 200 && response.data.code == 200) {
        const data = response.data.data;
        this.footerLogo = data.footerLogo;
        this.socialIconOne = data.linkedInIcon;
        this.socialIconTwo = data.facebookIcon;
        this.socialIconThree = data.instagramIcon;
        this.socialIconOneLink = data.linkedInLink;
        this.socialIconTwoLink = data.facebookLink;
        this.socialIconThreeLink = data.instagramLink;
        this.mailIcon = data.mailIcon,
        this.mailLink = data.mailLink,
        this.tiktokIcon = data.tiktokIcon,
        this.tiktokLink = data.tiktokLink,
        this.textFooter = data.policyText;
        this.textFooterLink = data.policyLink;
        this.copyright = data.copyRightText;
        return;
      }
    }
  }
</script>

<style></style>
