<template>
    <section-one
    :bannerTitle="privacyPolicy.bannerTitle"
    :sectionTwoDesc="privacyPolicy.sectionTwoDesc"
    ></section-one>
</template>

<script>
import $ from "jquery";
import axios from "axios";

import SectionOne from "../components/layout/privacy-policy/SectionOne.vue";

export default {
    components: {
        SectionOne,
    },
    data () {
      return {
        privacyPolicy: {}
      }
    },
    async mounted(){
        $('body').removeClass('noscroll');
            // call api for how goldie works page
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/privacy-policy`);
        if (response && response.status === 200) {
            const data = response.data.data;
            this.privacyPolicy = data;
            document.title = `${this.privacyPolicy.metaTitle || "Fit Goldie"}`;
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
            metaDescription.setAttribute('content', `${this.privacyPolicy.metaDesc || "Fit Goldie"}`);
            } else {
            const newMetaDescription = document.createElement('meta');
            newMetaDescription.setAttribute('name', `${this.privacyPolicy.metaTitle || "Fit Goldie"}`);
            newMetaDescription.setAttribute('content', `${this.privacyPolicy.metaDesc || "Fit Goldie"}`);
            document.head.appendChild(newMetaDescription);
            }

            // console.log("this.privacyPolicy------------");
            // console.log(this.privacyPolicy);
        }
    }
}
</script>

<style>
</style>