  <template>
  <div class="measurements-form-overlay"></div>
  <about-banner
    :bannerTitle="aboutData.bannerTitle"
  ></about-banner>
  <team-photo
    :bannerImage="aboutData.bannerImage"
    :aboutTitle="aboutData.sectionTwoTitle"
    :aboutDesc="aboutData.sectionTwoText"
    :journey="aboutData.sectionThreeTitle"
    :journeyImg="aboutData.sectionThreeImageOne"
  ></team-photo>
  <featured-in
    :featuredTitle="aboutData.sectionFourTitle"
    :featuredLogos="aboutData.sectionFourImages"
    :featuredLinkLogos="aboutData.newSectionFourImages"
  ></featured-in>
  <the-testimonial v-if="testimonials.length > 0" :alltestimonials="testimonials"
    :testimonialTitle="aboutData.sectionFourTestimonialTitle"
  ></the-testimonial>
  <notified-style
    :notifiedTitle="aboutData.sectionSixText"
  ></notified-style>
  <the-faq
    :faqTitle="aboutData.sectionSevenTitle"
    :faqsArrs="faqs"
  ></the-faq>
  <measurement-block></measurement-block>
  <how-to-measure></how-to-measure>
  <submit-retailer-popup></submit-retailer-popup>
  </template>

  <script>
  import $ from "jquery";
  import { gsap, ScrollTrigger, TimelineMax } from "gsap/all";
  import axios from "axios";

  import AboutBanner from "../components/layout/aboutus/AboutBanner.vue";
  import TeamPhoto from "../components/layout/aboutus/TeamPhoto.vue";
  import FeaturedIn from "../components/layout/aboutus/FeaturedIn.vue";
  import TheTestimonial from "../components/layout/testimonial/TheTestimonial.vue";
  import NotifiedStyle from "../components/layout/subscription/NotifiedStyle.vue";
  import TheFaq from "../components/layout/faq/TheFaq.vue";
  import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";
  import HowToMeasure from "../components/layout/how-to-measure/HowToMeasure.vue";
  import SubmitRetailerPopup from "../components/layout/submit-retailers/SubmitRetailerPopup.vue";

  export default {
    components: {
      AboutBanner,
      TeamPhoto,
      FeaturedIn,
      TheTestimonial,
      NotifiedStyle,
      TheFaq,
      MeasurementBlock,
      HowToMeasure,
      SubmitRetailerPopup
    },
    data() {
      return {
        aboutData: {},
        testimonials: [],
        faqs: []
      }
    },
    async mounted() {
      $('body').removeClass('noscroll');
      gsap.registerPlugin(ScrollTrigger);
      gsap.registerPlugin(TimelineMax);

      $(document).ready(function () {
        /*Testimonials slider*/
        $(".testimonial-slider").slick({
          infinite: false,
          /* adaptiveHeight: true, */
          /* centerMode: true, */
          prevArrow: ".slider-prev",
          nextArrow: ".slider-next",
          cssEase: "ease-in-out",
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });
        // Find the tallest div height
        var tallestHeight = 0;
        $('.testimonial-text').each(function() {
            var currentHeight = $(this).height();
            if (currentHeight > tallestHeight) {
                tallestHeight = currentHeight;
            }
        });
        // Assign the tallest height to all divs in the list
        $('.testimonial-text').css('height', tallestHeight + 'px');
        /*Testimonials slider*/
        /*FAQ Accordion*/
        $(document).on('click', '.accordion-button', function(){
          $(this).children('.arrow-icon').toggleClass('rotate');
        });
        /*FAQ Accordion*/

        /*Measurement button form toggle*/
        $('.measurement-btn').on('click', function () {
          $('.measurement-btn .arrow-icon').toggleClass('rotate');
        })

        // Define onStart function
        function onReverseCompleteFunction() {
          $('.measurement-btn .arrow-icon').removeClass('rotate');
        }
        function onStartFunction() {
          $('.measurement-btn .arrow-icon').addClass('rotate');
        }
        document.querySelector(".measurement-btn").addEventListener("click", animateIt);

        var tl = new TimelineMax();
        tl.to(".search-measurements-block", 0.1, {
          // y: 0,
          display: 'flex'
        })
          .to(".measurement-btn-block", 0.1, {
            marginTop: 0,
            onStart: onStartFunction,// Assign onStart callback function
            onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
          }, "<");
        tl.reverse(-1)
        tl.reversed(true);

        function animateIt() {
          tl.reversed(!tl.reversed());
        }
        /*Measurement button form toggle*/

        /*Measurements form toggle onscroll*/
        $(window).bind('scroll', function() {
          var scrollsec1 = $(window).scrollTop();
          var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
          var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
          if(currentElementOffsetscrollsec1 < 5){
            //console.log('second section reached top of screen');
            $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
            $('.measurement-block-onscroll .measurement-btn').fadeIn();
          }else{
            $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
            $('.measurement-block-onscroll .measurement-btn').fadeOut();
            $('.mb-os-form').hide();
          }
        });
        $('.measurement-block-onscroll .measurement-btn').on('click',function(){
            $('.mb-os-form').fadeToggle(100);
            /* $('body').addClass('noscroll'); */
            if($('.measurements-form-overlay').is(':visible')){
              $('.measurements-form-overlay').hide();
            }else{
              $('.measurements-form-overlay').show();
            }
            if($('body').hasClass('noscroll')){
              $('body').removeClass('noscroll')
            }else{
              $('body').addClass('noscroll')
            }
          });
        /*Measurements form toggle onscroll*/
        /*Measurements button to hide on scroll*/
        const showMeasurementAnim = gsap
          .from(".measurement-block-onscroll", {
            opacity: 0,
            paused: true,
            duration: 0.1,
          })
          .progress(1);

        ScrollTrigger.create({
          start: "top top",
          end: 999999,
          onUpdate: (self) => {
            self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
          },
        });
        /*Measurements button to hide on scroll*/
        /*Navigation to hide on scroll*/
        const showAnim = gsap
          .from(".top-nav", {
            yPercent: -150,
            paused: true,
            duration: 0.3,
          })
          .progress(1);

          ScrollTrigger.create({
            trigger:".sec-nav-blur",
            start: "top 50%",
            end: 999999,
            onUpdate: (self) => {
              self.direction === -1 ? showAnim.play() : showAnim.reverse();
            },
            // markers: true,
            // markers:{
            // 	startColor: '#f00',
            // 	endColor: '#9179CD',
            // 	fontSize: '2rem'
            // }
          });
        /*Navigation to hide on scroll*/
      })

      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/about-us`);
      if (response && response.status === 200) {
        const data = response.data.data;
        this.aboutData = data;
        document.title = `${this.aboutData.metaTitle || "Fit Goldie"}`;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${this.aboutData.metaDesc || "Fit Goldie"}`);
        } else {
          const newMetaDescription = document.createElement('meta');
          newMetaDescription.setAttribute('name', `${this.aboutData.metaTitle || "Fit Goldie"}`);
          newMetaDescription.setAttribute('content', `${this.aboutData.metaDesc || "Fit Goldie"}`);
          document.head.appendChild(newMetaDescription);
        }
        // console.log("this.aboutData;----");
        // console.log(this.aboutData);

        // call api for testimonials
        const testimonials = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/testimonial/all`);
        const testiData = testimonials.data.data;
        this.testimonials = testiData;
        // console.log(this.testimonials);

        // call api for FAQ
        const faqs = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/faqs/all`);
        this.faqs = faqs.data.data;
        if (this.faqs) {
          $(document).on('click', '.how-to-measure', function () {
            $('.popup-measurement').fadeIn();
            $('body').addClass('noscroll');
          });
          $(document).on('click', '.submit-retailer-link', function () {
            $('.popup-submit-retailer').css({ 'display': 'flex' });
            $('body').addClass('noscroll');
          });
        }
        // console.log(this.faqs);
        return this.aboutData;
      }
      // this.aboutData = this.$store.getters['pages/getHomeApiResponse'];
    },
    // created() {
    //   const scriptAbout = document.createElement('script');
    //   scriptAbout.src = '/js/aboutScreen/aboutScript.js'; // taking from public folder
    //   scriptAbout.async = true;
    //   document.head.appendChild(scriptAbout);
    // }
  }
  </script>

  <style>
  </style>
