<template>
  <section class="home-sec-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="how-it-works-block d-flex flex-wrap w-100">
            <div class="hiw-left">
              <h2 class="fs-56 fw-600 text-navyblue">{{sectionThreeTitle}}</h2>
              <div class="hiw-intro fs-18" v-html="sectionThreeDescrition">
              </div>
            </div>
            <div class="hiw-right">
              <div class="hiw-steps-block">
                <div class="hiw-step-single flex-wrap w-100 active">
                  <div class="hiw-step-single-left">
                    <img :src="`${baseUrl}${sectionThreeStepOneImg}`" alt="" class="home-hiw-step-icon">
                  </div>
                  <div class="hiw-step-single-right">
                    <div class="hiw-step-title d-flex flex-wrap w-100">
                      <h3 class="fs-24 fw-600">{{sectionThreeStepOneTitle}}</h3>
                      <div class="hiw-step-no">
                        <span>Step 01</span>
                      </div>
                    </div>
                    <div class="hiw-right-desc fs-18" v-html="sectionThreeStepOneDesc">
                    </div>
                    <div class="hiw-right-link" v-if="sectionThreeStepOneLink == 'none' ? false : true">
                      <a href="javascript:void(0);" class="fg-btn-link" :class="sectionThreeStepOneLink == 'measurement' ? 'how-to-measure' : sectionThreeStepOneLink == 'form' ? 'share-feedback-link' : ''">
                        <span>{{ sectionThreeStepOneLink == "measurement" ? "How to Measure " : sectionThreeStepOneLink == "none" ? "" : "Submit Feedback " }}</span>
                        <svg v-if="sectionThreeStepOneLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                            stroke="#1F5CD7" stroke-width="2" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="hiw-step-single flex-wrap w-100 inactive">
                  <div class="hiw-step-single-left">
                    <img :src="`${baseUrl}${sectionThreeStepTwoImg}`" alt="" class="home-hiw-step-icon">
                  </div>
                  <div class="hiw-step-single-right">
                    <div class="hiw-step-title d-flex flex-wrap w-100">
                      <h3 class="fs-24 fw-600">{{sectionThreeStepTwoTitle}}</h3>
                      <div class="hiw-step-no">
                        <span>Step 02</span>
                      </div>
                    </div>
                    <div class="hiw-right-desc fs-18" v-html="sectionThreeStepTwoDescription">
                    </div>
                    <div class="hiw-right-link" v-if="sectionThreeStepTwoLink == 'none' ? false : true">
                      <a href="javascript:void(0);" class="fg-btn-link" :class="sectionThreeStepTwoLink == 'measurement' ? 'how-to-measure' : sectionThreeStepTwoLink == 'form' ? 'share-feedback-link' : ''">
                        <span>{{ sectionThreeStepTwoLink == "measurement" ? "How to Measure " : sectionThreeStepTwoLink == "none" ? "" : "Submit Feedback " }}</span>
                        <svg v-if="sectionThreeStepTwoLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                            stroke="#1F5CD7" stroke-width="2" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="hiw-step-single flex-wrap w-100 inactive">
                  <div class="hiw-step-single-left">
                    <img :src="`${baseUrl}${sectionThreeStepThreeImg}`" alt="" class="home-hiw-step-icon">
                  </div>
                  <div class="hiw-step-single-right">
                    <div class="hiw-step-title d-flex flex-wrap w-100">
                      <h3 class="fs-24 fw-600">{{sectionThreeStepThreeTitle}}</h3>
                      <div class="hiw-step-no">
                        <span>Step 03</span>
                      </div>
                    </div>
                    <div class="hiw-right-desc fs-18" v-html="sectionThreeStepThreeDesc">
                    </div>
                    <div class="hiw-right-link" v-if="sectionThreeStepThreeLink == 'none' ? false : true">
                      <a href="javascript:void(0);" class="fg-btn-link" :class="sectionThreeStepThreeLink == 'measurement' ? 'how-to-measure' : sectionThreeStepThreeLink == 'form' ? 'share-feedback-link' : ''">
                        <span>{{ sectionThreeStepThreeLink == "measurement" ? "How to Measure " : sectionThreeStepThreeLink == "none" ? "" : "Submit Feedback " }}</span>
                        <svg v-if="sectionThreeStepThreeLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                            stroke="#1F5CD7" stroke-width="2" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="hiw-step-single flex-wrap w-100 inactive">
                  <div class="hiw-step-single-left">
                    <img :src="`${baseUrl}${sectionThreeStepFourImg}`" alt="" class="home-hiw-step-icon">
                  </div>
                  <div class="hiw-step-single-right">
                    <div class="hiw-step-title d-flex flex-wrap w-100">
                      <h3 class="fs-24 fw-600">{{sectionThreeStepFourTitle}}</h3>
                      <div class="hiw-step-no">
                        <span>Step 04</span>
                      </div>
                    </div>
                    <div class="hiw-right-desc fs-18" v-html="sectionThreeStepFourDesc">
                    </div>
                    <div class="hiw-right-link" v-if="sectionThreeStepFourLink == 'none' ? false : true">
                      <a href="javascript:void(0);" class="fg-btn-link" :class="sectionThreeStepFourLink == 'measurement' ? 'how-to-measure' : sectionThreeStepFourLink == 'form' ? 'share-feedback-link' : ''">
                        <span>{{sectionThreeStepFourLink == "measurement" ? "How to Measure " : sectionThreeStepFourLink == "none" ? "" : "Submit Feedback "}}</span>
                        <svg v-if="sectionThreeStepFourLink == 'none' ? false : true" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                            stroke="#1F5CD7" stroke-width="2" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import $ from "jquery";

  export default {
    props: ['sectionThreeTitle', 'sectionThreeDescrition', 'sectionThreeStepOneTitle', 'sectionThreeStepOneDesc', 'sectionThreeStepOneLink', 'sectionThreeStepTwoTitle', 'sectionThreeStepTwoDescription', 'sectionThreeStepTwoLink', 'sectionThreeStepThreeTitle', 'sectionThreeStepThreeDesc', 'sectionThreeStepThreeLink', 'sectionThreeStepFourTitle', 'sectionThreeStepFourDesc', 'sectionThreeStepFourLink', 'sectionThreeStepOneImg', 'sectionThreeStepTwoImg', 'sectionThreeStepThreeImg', 'sectionThreeStepFourImg'],
    data () {
      return {
      baseUrl: process.env.VUE_APP_BASE_URL
      };
    },
    mounted() {
      $(document).on('click', '.how-to-measure', function(){
        $('.popup-measurement').fadeIn();
        $('body').addClass('noscroll');
      })
      /*How it works section steps animation onscroll*/
			$(window).bind('scroll', function() {
				$('.hiw-step-single').each(function(){
					var scrollTop = $(window).scrollTop();
					var elementOffset = $(this).offset().top;
					var currentElementOffset = (elementOffset - scrollTop);
					if(currentElementOffset < 50 || currentElementOffset > 300){
						$(this).removeClass('active');
						$(this).addClass('inactive');
						$(this).children('.hiw-step-single-right').children('.hiw-step-title').children('.hiw-step-no').removeClass('active')
					}else{
						$(this).removeClass('inactive');
						$(this).addClass('active');
						$(this).children('.hiw-step-single-right').children('.hiw-step-title').children('.hiw-step-no').addClass('active')
					}
				})
			});
			/*How it works section steps animation onscroll*/
    }
  }
</script>

<style></style>
