<template>
  <p v-if="!linkIsValid">This link is invalid</p>
  <div v-if="linkIsValid" class="popup-signup-login" style="display: flex;">
    <div class="signup-login-content">
      <div class="login-signup-logo">
        <img src="../assets/images/logo-fitgoldie.svg" alt="" class="login-signup-logo-img">
      </div>
      <h3 class="login-signup-title fs-28 fw-700 text-white">Reset your password.</h3>
      <div id="login-form-popup" style="display: block;">
        <form @submit.prevent="submitResetPasswordFinalForm">
          <div class="home-banner-form d-flex flex-wrap w-100">
            <div class="text-box">
              <label for="userPassword">Password<sup>*</sup></label>
              <input id="userPassword" type="password" v-model="password" @input="checkPasswordEmpty" />
              <p v-if="passwordErr" class="text-danger" style="font-size:15px;margin-top: 5px;">Password is required.
              </p>
            </div>
            <div class="text-box">
              <label for="userpasswordConfirm">Confirm Password<sup>*</sup></label>
              <input id="userpasswordConfirm" type="password" v-model="confirmPassword"
                @input="checkConfirmPasswordEmpty" />
              <p v-if="confirmPasswordErr" class="text-danger" style="font-size:15px;margin-top: 5px;">Confirm password
                is required.</p>
              <p v-if="matchedPasswordErr" class="text-danger" style="font-size:15px;margin-top: 5px;">Password does not
                match.</p>
            </div>
            <!-- <div class="forgot-password-block">
            <a href="javascript:void(0);" class="toggle-link-gold forgot-toggle-link">Login</a>
          </div> -->
            <div class="home-form-cta signup-form-cta">
              <button type="submit" class="fg-btn-primary w-100">
                <span>Submit</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                    stroke="#0F2C66" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    props: ['token'],
    data() {
      return {
        password: "",
        passwordErr: false,
        confirmPassword: "",
        confirmPasswordErr: false,
        matchedPasswordErr: false,
        linkIsValid: true,
        formIsValid: true
      }
    },
    async beforeCreate() {
      let token = this.token;
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/account/reset/password/${token}`);
      if (response && response.status === 200) {
        if (response && response.status === 200 && response.data.code == 200) {
          const data = response.data.data;
          if (data.proceed) {
            this.linkIsValid = true;
            return;
          }
          this.linkIsValid = false;
        }
      } else {
        return this.$swal({
          position: "top",
          icon: false,
          title: false,
          html: "Something went wrong.",
          showConfirmButton: false,
          timer: 1900,
        });
      }
    },
    methods: {
      checkPasswordEmpty: function () {
        if (this.password) {
          this.passwordErr = false;
        } else {
          this.passwordErr = true;
        }
      },
      checkConfirmPasswordEmpty: function () {
        if (this.confirmPassword) {
          this.confirmPasswordErr = false;
        } else {
          this.confirmPasswordErr = true;
        }
      },
      submitResetPasswordFinalForm: async function () {
        this.formIsValid = true;
        if (!this.password) {
          this.passwordErr = true;
          this.formIsValid = false;
        }
        if (!this.confirmPassword) {
          this.confirmPasswordErr = true;
          this.formIsValid = false;
        }
        if (this.password !== this.confirmPassword) {
          this.matchedPasswordErr = true;
          this.formIsValid = false;
        }
        if (!this.formIsValid) {
          return;
        }
        const changePassword = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/account/change/password`, {
          token: this.token,
          password: this.password,
          confirmPassword: this.confirmPassword
        });
        if (changePassword && changePassword.status === 200 && changePassword.data.code == 200) {
          const data = changePassword.data;
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: data.message,
            showConfirmButton: false,
            timer: 1900,
          });
          setTimeout(() => {
            this.$router
              .push({ path: "/" })
              .then(() => { this.$router.go(0) });
          }, 1700);
          return;
        } else {
          return this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Something went wrong.",
            showConfirmButton: false,
            timer: 1900,
          });
        }
      }
    }
  }
</script>

<style></style>
