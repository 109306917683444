<template>
  <section class="home-sec-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="testimonial-title-rating">
            <!-- <img src="../../../assets/images/home-testimonial-rating.svg" alt=""> -->
             <div id="testimonial-rating-block"></div>
          </div>
          <h2 class="testimonial-title fs-48">{{ testimonialTitle }}</h2>
          <div class="testimonial-slider-block position-relative">
            <div class="testimonial-slider">
              <testimonial-card v-for="testimonial in alltestimonials"
                :key="testimonial._id"
                :name="testimonial.title"
                :description="testimonial.description"
              ></testimonial-card>
            </div>
            <div class="testimonial-slider-arrows-block slick-controls">
              <button class="slider-btn slider-prev">
                <svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="-0.5" y="0.5" width="87" height="87" rx="43.5" transform="matrix(-1 0 0 1 87.7969 0)"
                    stroke="#1F5CD7" />
                  <path
                    d="M44.7969 24C44.7969 26.1143 42.9635 29.2782 41.1101 31.9353C38.7235 35.3563 35.8752 38.3448 32.6069 40.6266C30.1584 42.3352 27.1853 43.9753 24.7969 43.9753M44.7969 64C44.7969 61.8857 42.9635 58.7218 41.1101 56.0647C38.7235 52.6439 35.8752 49.6552 32.6069 47.3734C30.1584 45.6648 27.1853 44.0247 24.7969 44.0247M24.7969 44H64.7969"
                    stroke="#1F5CD7" stroke-width="2" />
                </svg>
              </button>
              <button class="slider-btn slider-next">
                <svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.29688" y="0.5" width="87" height="87" rx="43.5" stroke="#1F5CD7" />
                  <path
                    d="M44.7969 24C44.7969 26.1143 46.6302 29.2782 48.4836 31.9353C50.8703 35.3563 53.7185 38.3448 56.9869 40.6266C59.4353 42.3352 62.4085 43.9753 64.7969 43.9753M44.7969 64C44.7969 61.8857 46.6302 58.7218 48.4836 56.0647C50.8703 52.6439 53.7185 49.6552 56.9869 47.3734C59.4353 45.6648 62.4085 44.0247 64.7969 44.0247M64.7969 44H24.7969"
                    stroke="#1F5CD7" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import $ from "jquery";
  import 'slick-carousel';
  import TestimonialCard from "../../ui/TestimonialCard.vue";
  import ScrollMagic from "scrollmagic";
  import lottie from 'lottie-web';

  export default {
    props: ["alltestimonials", "testimonialTitle"],
    components: {
      TestimonialCard
    },
    data() {
      return {};
    },
    async mounted() {
      /*Testimonial stars  animation scrollmagic*/
      // Load Lottie animation
      var animation = lottie.loadAnimation({
          container: document.getElementById('testimonial-rating-block'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/testimonial.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#testimonial-rating-block', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller)
      // .on('progress', function (e) {
      //     var progress = e.progress; // Get scroll progress (0 to 1)
      //     var frame = animation.totalFrames * progress;
      //     animation.goToAndStop(frame, true); // Go to corresponding frame
      //     // Check if reached the end and stop animation
      //     if (progress >= 1) {
      //         animation.goToAndStop(animation.totalFrames - 1, true); // Go to last frame
      //     }
      // });
      .on('enter', function () {          
        //about_animation.play(); //Play animation only once
        animation.goToAndStop(0, true);
        animation.play();          
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          animation.goToAndStop(animation.totalFrames - 1, true);
      });
      /*Testimonial stars animation scrollmagic*/
      /*Testimonials slider*/
      $(".testimonial-slider").slick({
        infinite: false,
        /* adaptiveHeight: true, */
        /* centerMode: true, */
        prevArrow: ".slider-prev",
        nextArrow: ".slider-next",
        cssEase: "ease-in-out",
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });
      // Find the tallest div height
      var tallestHeight = 0;
      $('.testimonial-text').each(function() {
          var currentHeight = $(this).height();
          if (currentHeight > tallestHeight) {
              tallestHeight = currentHeight;
          }
      });
      // Assign the tallest height to all divs in the list
      $('.testimonial-text').css('height', tallestHeight + 'px');
      /*Testimonials slider*/
    }
  }
</script>

<style></style>
