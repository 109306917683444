<template>
  <div class="testimonial-single">
    <div class="testimonial-text fs-16" v-html="description">
    </div>
    <div class="testimonial-name fs-24 fw-600 text-pink">
      <p>{{ name }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["name", "description"],
    data() {
      return {

      }
    }
  }
</script>

<style></style>
