<template>
  <div class="popup-submit-retailer">
    <a href="javascript:void(0);" class="popup-signup-close">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M30 10L10 30M10 10L30 30" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"></path>
      </svg>
    </a>
    <div class="signup-login-content">
      <div class="login-signup-logo">
        <img src="../../../assets/images/logo-fitgoldie.svg" alt="" class="login-signup-logo-img">
      </div>
      <h3 class="login-signup-title fs-28 fw-700 text-white">Retailer Submission.</h3>
      <div id="signup-form-popup" class="signup-form-popup">
        <form @submit.prevent="submitRetailerForm">
          <div class="home-banner-form d-flex flex-wrap w-100">
            <div class="text-box">
              <label for="retailername">Retailer Name<sup>*</sup></label>
              <input id="retailername" type="text" v-model="retailerName"/>
              <span v-for="error in v$.retailerName.$errors" :key="error.uid" class="label-error">{{ error.$message}}</span>
            </div>
            <div class="text-box">
              <label for="jeanstylename">Jeans Style Name<sup>*</sup></label>
              <input id="jeanstylename" type="text" v-model="styleName"/>
              <span v-for="error in v$.styleName.$errors" :key="error.uid" class="label-error">{{ error.$message}}</span>
            </div>
            <div class="text-box submit-retailer-jean-link">
              <label for="jeanproductlink">Jeans Product Link<sup>*</sup></label>
              <input id="jeanproductlink" type="text" v-model="productLink"/>
              <span v-for="error in v$.productLink.$errors" :key="error.uid" class="label-error">{{ error.$message}}</span>
            </div>
            <div class="home-form-cta signup-form-cta">
              <button type="submit" class="fg-btn-primary w-100">
                <span>Submit Details</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                    stroke="#0F2C66" stroke-width="2" />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  import { useVuelidate } from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';

  export default {
    data () {
      return {
        v$: useVuelidate(),
        retailerName: "",
        styleName: "",
        productLink: ""
      }
    },
    validations() {
      return {
        retailerName: {
          required: helpers.withMessage('Retailer name is required.', required)
        },
        styleName: {
          required: helpers.withMessage('Style name is required.', required)
        },
        productLink: {
          required: helpers.withMessage('Product URL is required.', required)
        }
      }
    },
    methods: {
      submitRetailerForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        if (!validateForm) return;
        let getToken = this.$store.getters.getToken;
        let headers = {};
        if (getToken) {
          headers.Authorization = "Bearer " + getToken;
        }
        const submitRetailer = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/retailer/create`, {
          retailerName: this.retailerName,
          knownFor: this.styleName,
          skuLink: this.productLink
        }, {
          headers
        });
        console.log(submitRetailer);
        alert("Retailer submitted!");
        this.retailerName = "";
        this.styleName = "";
        this.productLink = "";
        this.$router.go(0);
        return;
      }
    }
  }
</script>

<style>
</style>
