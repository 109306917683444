import { createRouter, createWebHistory } from 'vue-router';
import store from "./store/index.js";

import TheHome from "./pages/TheHome.vue";
import TheAboutUs from "./pages/TheAboutUs.vue";
import HowGoldieWorks from './pages/HowGoldieWorks.vue';
import PartnerWithUs from './pages/PartnerWithUs.vue';
import NotFound from "./pages/NotFound.vue";
import ForgotPassword from "./pages/ForgotPassword.vue";
import TheProducts from "./pages/TheProducts.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import TermsConditions from "./pages/TermsConditions.vue";
import MyProfile from "./pages/MyProfile.vue";
import ProductDetail from "./pages/ProductDetail.vue";
import MyFavourites from "./pages/MyFavourites.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: TheHome,
      meta: {
        title: 'Home',
        refreshPage: true,
        requiresApiResponse: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/about',
      name: 'AboutUs',
      component: TheAboutUs,
      meta: {
        title: 'About Us',
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/forgotPassword/:token',
      name: 'forgotPassword',
      component: ForgotPassword,
      props: true,
      meta: {
        title: 'Forgot Password',
        hideHeader: true,
        hideFooter: true
      }
    },
    {
      path: '/products',
      name: 'ProductPage',
      component: TheProducts,
      meta: {
        title: 'Products',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/how-goldie-works',
      name: 'howGoldieWorks',
      component: HowGoldieWorks,
      meta: {
        title: 'How Goldie Works',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/partner-with-us',
      name: 'partnerWithUs',
      component: PartnerWithUs,
      meta: {
        title: 'Partner With Us',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        title: 'PrivacyPolicy',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/terms-conditions',
      name: 'TermsConditions',
      component: TermsConditions,
      meta: {
        title: 'TermsConditions',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/my-favorites',
      name: 'MyFavourites',
      component: MyFavourites,
      meta: {
        title: 'MyFavourites',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/my-profile',
      name: 'MyProfile',
      component: MyProfile,
      meta: {
        title: 'MyProfile',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    {
      path: '/product/detail/:productSlug',
      name: 'ProductDetail',
      component: ProductDetail,
      props: true,
      meta: {
        title: 'MyProfile',
        refreshPage: true,
        hideHeader: false,
        hideFooter: false
      }
    },
    { path: '/:notFound(.*)', component: NotFound },

  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'ProductPage') {
    const measurement = store.getters['products/getMeasurementFromLocal'];
    if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
      next();
    } else {
      router.push("/");
      return;
    }
  } else if (to.name === 'MyProfile' || to.name === 'MyFavourites') {
    const isAuth = store.getters.isAuth;
    if (isAuth) {
      next();
    } else {
      router.push("/");
      return;
    }
  } else {
    next();
  }
});

export default router;
