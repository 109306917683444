<template>
  <div id="forgotpassword-form-popup">
    <form @submit.prevent="submitForgotPasswordForm">
      <p class="fs-16 text-white forgot-password-note">An email will be sent to your registered email id with a link to reset your password.</p>
      <div class="home-banner-form d-flex flex-wrap w-100">
        <div class="text-box">
          <label for="useremailForgotPass">Enter your email<sup>*</sup></label>
          <input id="useremailForgotPass" type="email" v-model.trim="email"/>
          <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
        </div>

        <div class="forgot-password-block">
          <a href="javascript:void(0);" class="toggle-link-gold login-toggle-link">Back to Login</a>
        </div>
        <div class="home-form-cta signup-form-cta">
          <button type="submit" class="fg-btn-primary w-100">
            <span>Send Mail</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                stroke="#0F2C66" stroke-width="2" />
            </svg>
          </button>
        </div>
        <div class="signin-toggle-block fs-16">
          <span class="text-white">Don't have an account?</span>
          <a href="javascript:void(0);" class="toggle-link-gold signup-toggle-link">Sign Up</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from "jquery";
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';

export default {
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      formIsValid: true
    }
  },
  validations () {
    return {
      email: {
        required: helpers.withMessage('Email is required.', required),
        email: helpers.withMessage('Invalid email address.', email),
      }
    }
  },
  methods: {
    submitForgotPasswordForm: async function () {
      this.v$.$touch();
      this.formIsValid = true;
      const validateForm = await this.v$.$validate();
      if (!validateForm) return;
      const forgotPasswordResponse = await this.$store.dispatch('forgotPasswordUser', {
        email: this.email
      });
      if (forgotPasswordResponse.code == 200) {
        this.v$.$reset();
        this.email = "";
        setTimeout(() => {
          this.$router
            .push({path: "/"})
            .then(() => { this.$router.go(0) });
        }, 2000);
      }
      return this.$swal({
        position: "top",
        icon: false,
        title: false,
        html: forgotPasswordResponse.message,
        showConfirmButton: false,
        timer: 1900,
      });
    }
  },
  mounted() {
    var self = this;
    $('.popup-signup-close').on('click',function(){
      self.v$.$reset();
      $('.popup-signup-login').hide();
      $('.popup-measurement').hide();
      $('.popup-submit-retailer').hide();
      $('.popup-feedback').hide();
      $('body').removeClass('noscroll');
    });
    $('.login-toggle-link').on('click',function(){
      self.v$.$reset();
      $('.signup-form-popup, #forgotpassword-form-popup').hide();
      $('#login-form-popup').show();
    });
    $('.signup-toggle-link').on('click',function(){
      self.v$.$reset();
      $('#login-form-popup,#forgotpassword-form-popup').hide();
      $('.signup-form-popup').show();
    });
  }
}
</script>

<style>

</style>
