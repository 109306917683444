  <template>
  <section class="fav-jeans-sec">
    <div class="fav-jeans-content-block">
      <div class="fav-jeans-content d-flex flex-wrap w-100">
        <div class="fjc-left">
          <div class="fjc-title">
            <h2 class="fs-48 fw-600">{{ favTitle }}</h2>
            <div class="fjc-text fs-16" v-html="favDesc">
            </div>
          </div>
          <div class="fjc-icon">
            <svg width="32" height="60" viewBox="0 0 32 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.2027 0C15.642 12.2398 19.3818 30.5748 31.6216 31.0141C19.3818 31.4534 15.642 47.3548 15.2027 59.5946C14.7634 47.3548 12.2398 31.4534 0 31.0141C12.2398 30.5748 14.7634 12.2398 15.2027 0Z"
                fill="#E114E3" />
              <animate attributeName="opacity" dur="2s" values="0.5;1;0.5" repeatCount="indefinite" begin="0.1" />
            </svg>
          </div>
        </div>
        <div class="fjc-right">
          <div class="fjc-right-slider-block">
            <div class="fjc-slide-item" v-for="favImage in favImages" :key="favImage">
              <img :src="`${baseUrl}${favImage}`" alt="" class="fjc-item-img">
            </div>
          </div>
        </div>        
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="fav-jeans-brand">
            <div class="brand-single" v-for="favLogo in favLogos" :key="favLogo">
              <img :src="`${baseUrl}${favLogo}`" alt="" class="brand-img">
            </div>
          </div>
          <div class="fav-jeans-brands-note">
            <p>{{ notAffilated }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  </template>

  <script>
  import $ from "jquery";
  import { gsap, ScrollTrigger } from "gsap/all";
  gsap.registerPlugin(ScrollTrigger);
  import 'slick-carousel';
  export default {
    props: ["favTitle", "favDesc", "favImages", "favLogos", "notAffilated"],
    data () {
      return {
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    mounted() {
      /*Favories jeans section offset calculation*/
      let sectionLeft = document.querySelector(".offset-block");
      let sectionLeftOffset = sectionLeft.getBoundingClientRect();
      const sectionLeftOffsetVal = sectionLeftOffset.left;
      if ($(window).width() > 767) {
        $('.fjc-left').css({ 'padding-left': sectionLeftOffsetVal });
        $('.godlies-favorites-content-block').css({ 'margin-left': sectionLeftOffsetVal });
      }
      $(window).resize(function(){
        let sectionLeft = document.querySelector(".offset-block");
        let sectionLeftOffset = sectionLeft.getBoundingClientRect();
        const sectionLeftOffsetVal = sectionLeftOffset.left;
        if ($(window).width() > 767) {
        $('.fjc-left').css({ 'padding-left': sectionLeftOffsetVal });
        }
      })
      if($(window).width() < 767){
        /* Favorite jeans mobile slider*/
        // $('.fjc-right-slider-block').slick({
        //   infinite: true,
        //   adaptiveHeight: true,
        //   arrows: false, // Disable arrows
        //   autoplay: true,
        //   autoplaySpeed: 1000, // Set the interval between slide transitions in milliseconds
        //   cssEase: "ease-in-out",
        //   slidesToShow: 1,
        //   dots: false,
        // });
        /* Favorite jeans mobile slider*/
        /*Goldies top picks mobile slider*/
        $('.goldies-top-picks-block-3.goldies-top-picks-block').slick({
          infinite: false,
          adaptiveHeight: true,
          arrows: false, // Disable arrows
          cssEase: "ease-in-out",
          slidesToShow: 1,
          dots: false,
        });
        /*Goldies top picks mobile slider*/
      }
      
      /* function initSlick() {
        if ($(window).width() < 767) {
          if (!$('.fav-jeans-brand').hasClass('slick-initialized')) {
            $('.fav-jeans-brand').slick({
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 3000,
              arrows: false // Hide previous and next buttons
            });
          }
          if (!$('.fjc-right-slider-block').hasClass('slick-initialized')) {
            $('.fjc-right-slider-block').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 2000,
              arrows: false // Hide previous and next buttons
            });
          }
        } else {
          if ($('.fav-jeans-brand').hasClass('slick-initialized')) {
            $('.fav-jeans-brand').slick('unslick');
          }
          if ($('.fjc-right-slider-block').hasClass('slick-initialized')) {
            $('.fjc-right-slider-block').slick('unslick');
          }
        }
      }

      initSlick();
      $(window).on('resize', function() {
        initSlick();
      }); */

      // function initSlick() {
      //   if ($(window).width() < 767) {          
      //     if (!$('.fjc-right-slider-block').hasClass('slick-initialized')) {
      //       $('.fjc-right-slider-block').slick({
      //         slidesToShow: 1,
      //         slidesToScroll: 1,
      //         autoplay: true,
      //         autoplaySpeed: 2000,
      //         arrows: false // Hide previous and next buttons
      //       });
      //     }
      //   } else {          
      //     if ($('.fjc-right-slider-block').hasClass('slick-initialized')) {
      //       $('.fjc-right-slider-block').slick('unslick');
      //     }
      //   }
      // }

      // initSlick();
      // $(window).on('resize', function() {
      //   initSlick();
      // });
  
    }
  }
  </script>

  <style></style>
