<template>
  <section class="newsletter-sec" id="newsletter-sec">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="newsletter-content-block d-flex flex-wrap w-100">
            <!-- <img src="../../../assets/images/newsletter-shopping-bags.svg" alt="" class="newsletter-form-graphic-img"> -->
            <div id="newsletter-bags"></div>
            <div class="newsletter-graphic">
              <img src="../../../assets/images/newsletter-graphic.svg" alt="" class="newsletter-graphic-img">
            </div>
            <div class="newsletter-form-block">
              <h2 class="fs-40 fw-600" style="text-transform: none;">{{ notifiedTitle }}</h2>
              <!-- <h2 class="fs-40 fw-600" style="text-transform: none;">Get Notified of New Styles</h2> -->
              <form class="newsletter-form" @submit.prevent="submitSubscriptionForm">
                <input type="text" class="newsletter-form-input" placeholder="Enter Email" v-model.trim="email">
                <button type="submit" class="fg-btn-primary fg-btn-darkblue">
                  <span>Subscribe</span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                      stroke="#fff" stroke-width="2" />
                  </svg>
                </button>
                <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import { useVuelidate } from '@vuelidate/core';
  import { required, email, helpers } from '@vuelidate/validators';
  import $ from "jquery";
  //import { gsap, ScrollTrigger } from "gsap/all";
  import ScrollMagic from "scrollmagic";
  import lottie from 'lottie-web';

  export default {
    props: ["notifiedTitle"],
    data () {
      return {
        v$: useVuelidate(),
        email: ""
      }
    },
    validations () {
      return {
        email: {
          required: helpers.withMessage('Email is required.', required),
          email: helpers.withMessage('Invalid email address.', email),
        }
      }
    },
    async mounted() {
        /*Newsletter bags animation scrollmagic*/
        // Load Lottie animation
        var newsletter_animation = lottie.loadAnimation({
            container: document.getElementById('newsletter-bags'), // The DOM element to render the animation in
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: 'animations/newsletter.json' // The path to the animation JSON file
        });

        // Initialize ScrollMagic Controller
        var controller_newsletter_animation = new ScrollMagic.Controller();

        // Create a ScrollMagic Scene
        new ScrollMagic.Scene({
            triggerElement: '#newsletter-sec', // The element that triggers the animation
            duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
            triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
        })
        .addTo(controller_newsletter_animation)
        .on('enter', function () {
            // Add a delay of 5 seconds before starting the animation
            setTimeout(function () {
                newsletter_animation.play();
                // Reset and play the animation when entering the scene
                newsletter_animation.goToAndStop(0, true);
                newsletter_animation.play();
            }, 0); // 5000 milliseconds = 5 seconds
        })
        .on('leave', function () {
            // Hold the end state of the animation when leaving the scene
            newsletter_animation.goToAndStop(newsletter_animation.totalFrames - 1, true);
        });
      /*Newsletter bags animation scrollmagic*/
      //const animationTriggerNewsletter = document.getElementById('newsletter-sec');
      //const animationTargetNewsletter = document.getElementById('newsletter-bags');


      // // Load Lottie animation
      // const animation1 = lottie.loadAnimation({
      //   container: animationTargetNewsletter,
      //   renderer: 'svg',
      //   loop: false,
      //   autoplay: false,
      //   path: '/animations/newsletter.json' // Adjust the path as needed
      // });

      // // Ensure ScrollTrigger is registered
      // gsap.registerPlugin(ScrollTrigger);

      // // Configure ScrollTrigger
      // ScrollTrigger.create({
      //   target: animationTargetNewsletter,
      //   trigger: animationTriggerNewsletter,
      //   start: 'top 50%',
      //   end: 'top 50%',
      //   scrub: 3,
      //   markers: false,
      //   // delay: 2,
      //   pin: false,
      //   onUpdate: self => {
      //     const progress = self.progress;
      //     const totalFrames = animation1.totalFrames;
      //     const frame = Math.floor(totalFrames * progress);
      //     animation1.goToAndStop(frame, true);
      //   }
      // });
      $(document).ready(function () {

        // LottieScrollTrigger({
        //   target: "#newsletter-bags",
        //   trigger: "#newsletter-sec",
        //   start: 'top 50%',
        //   end: 'top 50%',
        //   path: 'animations/newsletter.json',
        //   scrub: 3,
        //   delay: 2,
        //   pin: false
        // });
        // // helper function
        // function LottieScrollTrigger(vars) {
        //   //console.log(`LottieScrollTrigger function called for animation ${animationId} with vars:`, vars); // Log the vars object with animationId
        //   let playhead = {frame: 0},
        //     target = gsap.utils.toArray(vars.target)[0],
        //     speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
        //     st = {trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1},
        //     animation = lottie.loadAnimation({
        //       container: target,
        //       renderer: vars.renderer || "svg",
        //       loop: false,
        //       autoplay: false,
        //       path: vars.path
        //     });
        //   for (let p in vars) { // let users override the ScrollTrigger defaults
        //     st[p] = vars[p];
        //   }
        //   //console.log(`ScrollTrigger configuration for animation ${animationId}:`, st); // Log the ScrollTrigger configuration with animationId
        //   animation.addEventListener("DOMLoaded", function() {
        //     gsap.to(playhead, {
        //       duration: vars.duration || 0.5,
        //       delay: vars.delay || 5,
        //       frame: (animation.totalFrames - 1) * (vars.progress || 1),
        //       ease: vars.ease || "none",
        //       onUpdate: () => animation.goToAndStop(playhead.frame, true),
        //       scrollTrigger: st
        //     });
        //     // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
        //     ScrollTrigger.sort();
        //     ScrollTrigger.refresh();

        //     /* console.log(`Animation ${animationId} loaded and ScrollTrigger added`); // Log a message to indicate successful setup for animationId */
        //   });
        //   return animation;
        // }
      });
    },
    methods: {
      submitSubscriptionForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        if (!validateForm) return;
        let getToken = this.$store.getters.getToken;
        let headers = {};
        if (getToken) {
          headers.Authorization = "Bearer " + getToken;
        }
        const submitSubscriptionResponse = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/subscription/email/add`, {
          email: this.email
        }, {
          headers
        });
        if (submitSubscriptionResponse && submitSubscriptionResponse.status === 200 && submitSubscriptionResponse.data.code == 200) {
          this.email = "";
          this.v$.$reset();
          // alert("Submiited!");
          return this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: submitSubscriptionResponse.data.message,
            showConfirmButton: false,
            timer: 1900,
          });
        } else {
          this.email = "";
          // alert("Submiited!");
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Something went wrong.",
            showConfirmButton: false,
            timer: 1700,
          });
          setTimeout(() => { this.$router.go(); }, 1700);
          return;
        }
      }
    },
  }
</script>

<style></style>
